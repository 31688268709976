<script>
  import { computed, defineComponent } from "@vue/composition-api";
  import VueApexCharts from "vue-apexcharts";

  import { getBurnDownByProjectId } from "./useProjectBurndown.js";

  export default defineComponent({
    name: "vue-chart",
    components: {
      apexcharts: VueApexCharts
    },
    setup() {
      const {
        projectBurnDowns,
        projectBurnDownsHaveLoaded,
        projectBurnDownsArePending
      } = getBurnDownByProjectId();

      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric"
      };

      const todaysDate = new Date().toLocaleString("en-US", options);
      const chartOptions = computed(() => {
        return {
          chart: {
            id: "vue-chart"
          },
          stroke: {
            show: true,
            curve: "straight",
            lineCap: "round",
            width: 2
          },
          title: {
            text: "Percent Funds Remaining as a Function of Time",
            align: "left",
            style: {
              fontSize: "18px",
              fontWeight: "bold",
              fontFamily: "Inter, sans-serif",
              color: "#47424fde"
            }
          },
          subtitle: {
            text: `Current as of ${todaysDate}`,
            align: "left",
            style: {
              fontSize: "14px",
              fontWeight: "bold",
              fontFamily: "Inter, sans-serif",
              color: "#8c8c8c"
            }
          },
          tooltip: {
            enabled: true
          },
          noData: {
            text: "Loading..."
          },
          markers: {
            size: 3
          },
          legend: {
            show: true,
            showForSingleSeries: false,
            position: "bottom",
            horizontalAlign: "center",
            height: 50,
            offsetY: 20
          },
          xaxis: {
            type: "datetime",
            categories: projectBurnDowns.value.map((p) => p.item_date),
            crosshairs: {
              show: true,
              width: 3,
              position: "front",
              opacity: 1,
              stroke: {
                color: "#000000",
                width: 2,
                dashArray: 0
              }
            },
            tooltip: {
              enabled: true,
              offsetY: 5,
              formatter: (value) => {
                return new Date(value).toLocaleString("en-US", {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric"
                });
              },
              style: {
                fontSize: "14px",
                fontFamily: "Helvetica, Arial, sans-serif"
              }
            }
          },
          yaxis: {
            forceNiceScale: true,
            labels: {
              show: true,
              align: "right",
              style: {
                fontSize: "12px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 400,
                cssClass: "apexcharts-y-axis-label"
              },
              formatter: (value) => {
                return `${value}%`;
              }
            },
            title: {
              text: "Percent Remaining",
              rotate: -90,
              style: {
                color: "#47424fde",
                fontSize: "14px",
                fontFamily: "Inter, sans-serif",
                fontWeight: "bold"
              }
            }
          }
        };
      });
      const series = computed(() => {
        return [
          {
            name: "Actual Remaining",
            data: projectBurnDowns.value.map((b) => b.remaining_actual)
          },
          {
            name: "Estimated Remaining",
            data: projectBurnDowns.value.map((b) => b.remaining_est)
          }
        ];
      });

      return {
        todaysDate,
        chartOptions,
        series,
        projectBurnDowns,
        projectBurnDownsHaveLoaded,
        projectBurnDownsArePending
      };
    }
  });
</script>
<template>
  <div>
    <v-card v-if="projectBurnDownsHaveLoaded" flat>
      <v-card-text>
        <apexcharts
          max-width="850"
          type="line"
          :options="chartOptions"
          :series="series"
        ></apexcharts>
      </v-card-text>
    </v-card>
    <v-card v-else height="191" flat>
      <v-overlay absolute color="white" opacity="0.75">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-overlay>
    </v-card>
  </div>
</template>

<style scoped>
  .apexcharts-xaxistooltip {
    background: #cdcdcd;
    border: 2 solid #000000;
  }
  .apexcharts-xcrosshairs {
    stroke-linecap: round;
    stroke-width: 8px;
    stroke-dasharray: 1;
    stroke: aqua;
  }
</style>
