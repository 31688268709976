<script>
  import { mdiCheckBold } from "@mdi/js";
  import { defineComponent } from "@vue/composition-api";

  import { useConstStatusBar } from "./useProjectStatusBar";
  export default defineComponent({
    props: {
      projectStatus: {
        type: String,
        required: true
      }
    },
    setup(props) {
      const { constStatuses, activeStep } = useConstStatusBar(
        props.projectStatus
      );

      return {
        constStatuses,
        activeStep,
        icons: {
          mdiCheckBold
        }
      };
    }
  });
</script>

<template>
  <v-stepper v-model="activeStep" flat class="custom-header" alt-labels>
    <!-- Header -->
    <v-stepper-header>
      <!-- Header: Step 1 -->
      <v-stepper-step
        :complete="activeStep > 1"
        step="1"
        color="primary"
        :complete-icon="icons.mdiCheckBold"
      >
        <div class="d-flex flex-column text-center text-no-wrap">
          <span
            class="text-sm font-weight-semibold"
            :class="activeStep > 1 ? 'primary--text' : 'black--text'"
          >
            NTP
          </span>
        </div>
      </v-stepper-step>

      <v-divider></v-divider>

      <!-- Header: Step 2 -->
      <v-stepper-step
        :complete="activeStep > 2"
        step="2"
        color="primary"
        :complete-icon="icons.mdiCheckBold"
      >
        <div class="d-flex flex-column text-center text-no-wrap">
          <span
            class="text-sm font-weight-semibold"
            :class="activeStep > 2 ? 'primary--text' : 'black--text'"
          >
            Active<br />Construction
          </span>
        </div>
      </v-stepper-step>

      <v-divider></v-divider>

      <!-- Header: Step 3 -->
      <v-stepper-step
        :complete="activeStep > 3"
        step="3"
        color="primary"
        :complete-icon="icons.mdiCheckBold"
      >
        <div class="d-flex flex-column text-center text-no-wrap">
          <span
            class="text-sm font-weight-semibold"
            :class="activeStep > 3 ? 'primary--text' : 'black--text'"
          >
            Substantially<br />Complete
          </span>
        </div>
      </v-stepper-step>

      <v-divider></v-divider>

      <!-- Header: Step 4 -->
      <v-stepper-step
        :complete="activeStep > 4"
        step="4"
        color="primary"
        :complete-icon="icons.mdiCheckBold"
      >
        <div class="d-flex flex-column text-center text-no-wrap">
          <span
            class="text-sm font-weight-semibold"
            :class="activeStep > 4 ? 'primary--text' : 'black--text'"
          >
            Complete
          </span>
        </div>
      </v-stepper-step>

      <v-divider></v-divider>

      <!-- Header: Step 5 -->
      <v-stepper-step
        :complete="activeStep > 5"
        step="5"
        color="primary"
        :complete-icon="icons.mdiCheckBold"
      >
        <div class="d-flex flex-column text-center text-no-wrap">
          <span
            class="text-sm font-weight-semibold"
            :class="activeStep > 5 ? 'primary--text' : 'black--text'"
          >
            Closed<br />Out
          </span>
        </div>
      </v-stepper-step>
    </v-stepper-header>
  </v-stepper>
</template>
