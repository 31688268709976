/**
 * @file Contracts
 * @author Anne Canoune
 * @version 0.1.0
 * @description All of the queries associated with the Contracts service
 */

/**
 * Find All Contracts
 * @description Returns all contracts.
 *
 *
 * @returns {object}
 */

function findAllContracts() {
  return {
    query: {
      $limit: 100,
      $sort: {
        contract_num: 1
      }
    }
  };
}
exports.findAllContracts = findAllContracts;
