<script>
  import {
    mdiCalendar,
    mdiCalendarClockOutline,
    mdiMagnify,
    mdiPlus,
    mdiTrashCanOutline
  } from "@mdi/js";
  import { defineComponent, ref, watch } from "@vue/composition-api";

  import { permitTypeIcon } from "@/composables/usePermitTypes";
  import { avatarText } from "@/helpers/filter";
  import { store } from "@/store";
  import {
    useAssignConstructionRecordDialog,
    usePermitListLookup
  } from "@/views/apps/construction-record/useConstructionRecord";
  import { useProject } from "@/views/apps/project/useProject";

  export default defineComponent({
    props: {
      isPermitListEditDialogOpen: {
        type: Boolean,
        required: true
      },
      editItems: {
        type: Array,
        required: true
      }
    },
    setup(props, { emit }) {
      const isLoading = ref(false);
      const { search, permits, latestPermitsQuery } = usePermitListLookup();
      const {
        loading,
        selectedPermits,
        tableHeaders,
        removeConstructionRecord
      } = useAssignConstructionRecordDialog();
      const { project } = useProject();

      // form submit
      const handleFormSubmit = (editItems, project) => {
        // createItem.redirectOnSuccess = redirectOnSuccess.value;
        emit("save", {
          editItems: editItems,
          project_record: project.fulcrum_id,
          project_name: project.proceed_name,
          project_num: project.proceed_num
        });
        emit("close-dialog", false);
      };

      // form cancel
      const onCancel = () => {
        emit("close-dialog", false);
      };

      // remove permit from table
      const handlePermitRemoval = (item) => {
        selectedPermits.value = selectedPermits.value.filter(
          (i) => i.permit_id !== item.permit_num
        );
        emit("remove", item);
      };

      watch(search, (value) => {
        if (permits.length > 0) return;

        isLoading.value = true;
        setTimeout(async () => {
          await store.dispatch("permits/filterText", value);
        }, 500);
        isLoading.value = false;
      });

      watch(loading, () => {
        setTimeout(() => (loading.value = false), 5000);
      });
      return {
        avatarText,
        permitTypeIcon,
        isLoading,

        // usePermitListLookup
        search,
        latestPermitsQuery,

        // useSelectedPermitDatatable
        loading,
        selectedPermits,
        tableHeaders,
        removeConstructionRecord,
        handlePermitRemoval,
        handleFormSubmit,
        onCancel,

        project,
        // icons
        icons: {
          mdiCalendar,
          mdiCalendarClockOutline,
          mdiMagnify,
          mdiTrashCanOutline,
          mdiPlus
        }
      };
    }
  });
</script>

<template>
  <!-- dialog -->
  <v-dialog
    v-if="project"
    :value="isPermitListEditDialogOpen"
    persistent
    max-width="850px"
  >
    <v-card>
      <v-card-title> Assign Permits </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div
          class="d-flex justify-space-between flex-wrap flex-column flex-sm-row"
        >
          <div class="pa-5 flex-grow-1">
            <v-row class="mb-5">
              <div class="text font-weight-medium">
                Search for and select a permit from below to associate a permit
                with this project.
              </div>
            </v-row>
            <v-row>
              <v-col cols="12">
                <label
                  for="permits"
                  class="label-color font-weight-semibold mb-2 d-block"
                >
                  Permits Search
                </label>
                <v-autocomplete
                  id="permits"
                  v-if="latestPermitsQuery"
                  v-model="selectedPermits"
                  :items="latestPermitsQuery.response.data"
                  :search-input.sync="search"
                  :loading="isLoading"
                  return-object
                  multiple
                  dense
                  hide-selected
                  item-text="permit_id"
                  item-value="fulcrum_id"
                  placeholder="Search for permit(s) to associate with this project..."
                  outlined
                  :prepend-inner-icon="icons.mdiMagnify"
                  @input="$emit('update', selectedPermits)"
                >
                  <!-- No Data: Customize the No Data message in the dropdown -->
                  <template v-slot:no-data>
                    <v-list-item class="my-3">
                      Associate <strong>&nbsp;permits&nbsp;</strong> with this
                      project
                    </v-list-item>
                  </template>

                  <template v-slot:selection=""></template>

                  <template v-slot:item="{ item }">
                    <v-list-item-avatar
                      tile
                      size="26"
                      color="transparent"
                      class="headline font-weight-light"
                    >
                      <v-icon left>
                        {{ permitTypeIcon(item.permit_type[0]) }}
                      </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="item.permit_id"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-text="item.permit_desc"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon small color="#5DC174">
                        {{ icons.mdiPlus }}
                      </v-icon>
                    </v-list-item-action>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <!-- data table of selected permits -->
                <v-data-table
                  :headers="tableHeaders"
                  :items="editItems"
                  item-key="permit_record"
                  disable-sort
                  hide-default-footer
                  no-data-text="No permits selected"
                >
                  <!-- name -->
                  <template #[`item.permit_id`]="{ item }">
                    <div class="d-flex align-center">
                      <v-avatar
                        :color="item.permit_type ? '' : 'primary'"
                        :class="
                          item.permit_type
                            ? ''
                            : 'v-avatar-light-bg primary--text'
                        "
                        size="32"
                      >
                        <v-icon v-if="item.permit_type">
                          {{ permitTypeIcon(item.permit_type[0]) }}
                        </v-icon>
                        <span v-else class="font-weight-medium">
                          {{ avatarText(item.permit_num) }}
                        </span>
                      </v-avatar>

                      <div class="d-flex flex-column ms-3">
                        <span class="text--primary font-weight-semibold">
                          {{ item.permit_num }}
                        </span>
                        <small>{{ item.permit_desc }}</small>
                      </div>
                    </div>
                  </template>
                  <!-- actions -->
                  <!-- NOTE: The v-slot modifier is NOT an error -->
                  <template v-slot:item.actions="{ item }">
                    <v-icon
                      @click="handlePermitRemoval(item)"
                      class="mr-2"
                      color="error"
                    >
                      {{ icons.mdiTrashCanOutline }}
                    </v-icon>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn type="reset" color="secondary" text small @click="onCancel">
          Cancel
        </v-btn>
        <v-btn
          type="submit"
          color="primary"
          small
          @click="handleFormSubmit(editItems, project)"
        >
          Save
        </v-btn>
      </v-card-actions>
      <v-overlay
        v-if="loading"
        absolute
        color="white"
        opacity="0.40"
        fill-height
      >
        <v-progress-circular indeterminate color="primary">
          <span class="mt-16">Saving...</span>
        </v-progress-circular>
      </v-overlay>
    </v-card>
  </v-dialog>
</template>
