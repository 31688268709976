<script>
  import { mdiCalendarMonth, mdiClockTimeThreeOutline } from "@mdi/js";
  import { defineComponent, ref } from "@vue/composition-api";
  // Using VeeValidate for validations
  import { ValidationObserver, ValidationProvider } from "vee-validate";

  // Notification
  import { snackbar } from "@/components/base/snackbar/useSnackbar";
  // Bring in Date Picker Controls
  import {
    //useAssignedDatePicker,
    useCalculatedDates,
    useCloseOutDatePicker,
    useDayEndTimePicker,
    useDayStartTimePicker,
    useEndDatePicker,
    useHandOffDatePicker,
    useNightEndTimePicker,
    useNightStartTimePicker,
    useProceedDatePicker,
    useRainStartDatePicker,
    useStartDatePicker
  } from "@/views/apps/project/project-form-timeline/useProjectFormTimeline";
  import { useProjectDialog } from "@/views/apps/project/useProject";

  export default defineComponent({
    components: {
      ValidationProvider,
      ValidationObserver
    },
    props: {
      isProjectKeyDatesDialogOpen: {
        type: Boolean,
        required: true
      },
      editItem: {
        type: Object,
        required: true
      },
      projectPhase: {
        type: String,
        required: true
      }
    },
    setup(props, { emit }) {
      const form = ref(null);
      const overlay = ref(false);
      //const { assignedDate, assignedDateMenu } = useAssignedDatePicker();
      const { handOffDate, handOffDateMenu } = useHandOffDatePicker();
      const { proceedDate, proceedDateMenu } = useProceedDatePicker();
      const { rainStartDate, rainStartDateMenu, resolveMinRainStartDate } =
        useRainStartDatePicker();
      const { dayStartTime, dayStartTimeMenu } = useDayStartTimePicker();
      const { dayEndTime, dayEndTimeMenu } = useDayEndTimePicker();
      const { nightStartTime, nightStartTimeMenu } = useNightStartTimePicker();
      const { nightEndTime, nightEndTimeMenu } = useNightEndTimePicker();
      const { startDate, startDateMenu } = useStartDatePicker();
      const { endDate, endDateMenu } = useEndDatePicker();
      const { closeOutDate, closeOutDateMenu } = useCloseOutDatePicker();
      const {
        resolveHandOffToNtpDate,
        resolveDurationDays,
        resolveEstimateStartDate,
        resolveEstimateEndDate
      } = useCalculatedDates();

      const { loading } = useProjectDialog();

      // if project phase = traffic the following fields should be visible:
      // assigned to date (assign_date)
      // hand off date (hand_off_date)
      // notice to proceed (proceed_date)
      // day start time
      // day end time
      // night start time
      // night end time

      // if project phase = construction the following fields should be visible:
      // start_date (start_date)
      // rain start date (rain_start_date)
      // actual completion date (end_date)
      // close out date (close_out_date)

      // form submit
      const handleFormSubmit = async () => {
        try {
          handleCalculatedDates(props.editItem);
          // console.log(props.editItem);
          // console.log("All Done!");
          emit("save", props.editItem);
          form.value.reset();
        } catch (error) {
          snackbar({
            text: `Unable to submit form. Please contact your Administrator.`,
            color: "error"
          });
        }
      };

      // form cancel
      const onCancel = () => {
        form.value.reset();
        emit("close-dialog", false);
      };

      const handleCalculatedDates = (editItem) => {
        editItem.calc_hand_off_ntp = resolveHandOffToNtpDate(editItem);
        editItem.duration_days = resolveDurationDays(editItem);
        editItem.estimate_start_date = resolveEstimateStartDate(editItem);
        editItem.estimate_end_date = resolveEstimateEndDate(editItem);
      };
      return {
        form,
        loading,
        // assignedDate,
        // assignedDateMenu,
        handOffDate,
        handOffDateMenu,
        proceedDate,
        proceedDateMenu,
        rainStartDate,
        rainStartDateMenu,
        dayStartTime,
        dayStartTimeMenu,
        dayEndTime,
        dayEndTimeMenu,
        nightStartTime,
        nightStartTimeMenu,
        nightEndTime,
        nightEndTimeMenu,
        startDate,
        startDateMenu,
        endDate,
        endDateMenu,
        closeOutDate,
        closeOutDateMenu,
        overlay,
        handleFormSubmit,
        onCancel,

        // calculated values
        resolveHandOffToNtpDate,
        resolveDurationDays,
        resolveEstimateStartDate,
        resolveEstimateEndDate,
        resolveMinRainStartDate,

        // icons
        icons: {
          mdiCalendarMonth,
          mdiClockTimeThreeOutline
        }
      };
    }
  });
</script>

<template>
  <validation-observer ref="form" v-slot="{ invalid, reset }">
    <v-dialog
      :value="isProjectKeyDatesDialogOpen"
      scrollable
      persistent
      max-width="550px"
    >
      <v-card>
        <v-card-title> Edit Project Schedule </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <form @submit.prevent="handleFormSubmit" @reset.prevent="reset">
            <section v-if="projectPhase === 'Design'">
              <div class="text-lg primary--text font-weight-semibold mb-4">
                Project Dates
              </div>
              <!-- Assigned to Designer Date: Visible in Design Phase -->
              <!-- <validation-provider
                v-slot="{ errors }"
                name="Assigned Date"
                rules="required_if:proceed"
              >
                <label
                  for="assigned-date-menu"
                  class="label-color font-weight-semibold mb-2 d-block"
                >
                  Date Assigned to Designer
                </label>
                <v-menu
                  ref="assignedDateMenuRef"
                  v-model="assignedDateMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      id="assigned-date-menu"
                      v-model="editItem.assign_date"
                      readonly
                      v-bind="attrs"
                      dense
                      outlined
                      clearable
                      v-on="on"
                      :prepend-inner-icon="icons.mdiCalendarMonth"
                      :error-messages="errors"
                    ></v-text-field>
                  </template>

                  <v-date-picker
                    v-model="editItem.assign_date"
                    no-title
                    scrollable
                    @input="assignedDateMenu = false"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="assignedDateMenu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="
                        $refs.assignedDateMenuRef.save(editItem.assign_date)
                      "
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </validation-provider> -->

              <!-- Hand Off Date: Visible in Design Phase -->
              <validation-provider
                v-slot="{ errors }"
                name="Hand Off Date"
                rules="required_if:proceed"
              >
                <label
                  for="hand-off-date-menu"
                  class="label-color font-weight-semibold mb-2 d-block"
                >
                  Hand Off Date
                </label>
                <v-menu
                  ref="handOffDateMenuRef"
                  v-model="handOffDateMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      id="hand-off-date-menu"
                      v-model="editItem.hand_off_date"
                      readonly
                      v-bind="attrs"
                      dense
                      outlined
                      clearable
                      v-on="on"
                      :prepend-inner-icon="icons.mdiCalendarMonth"
                      :error-messages="errors"
                    ></v-text-field>
                  </template>

                  <v-date-picker
                    v-model="editItem.hand_off_date"
                    no-title
                    scrollable
                    @input="handOffDateMenu = false"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="handOffDateMenu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="
                        $refs.handOffDateMenuRef.save(editItem.hand_off_date)
                      "
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </validation-provider>

              <!-- Proceed Date: Visible in Design Phase -->
              <validation-provider
                v-slot="{ errors }"
                name="Proceed Date"
                rules=""
                vid="proceed"
              >
                <label
                  for="proceed-date-menu"
                  class="label-color font-weight-semibold mb-2 d-block"
                >
                  Proceed Date
                </label>
                <v-menu
                  ref="proceedDateMenuRef"
                  v-model="proceedDateMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      id="proceed-date-menu"
                      v-model="editItem.proceed_date"
                      readonly
                      v-bind="attrs"
                      dense
                      outlined
                      clearable
                      v-on="on"
                      :prepend-inner-icon="icons.mdiCalendarMonth"
                      :error-messages="errors"
                    ></v-text-field>
                  </template>

                  <v-date-picker
                    v-model="editItem.proceed_date"
                    no-title
                    scrollable
                    @input="proceedDateMenu = false"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="proceedDateMenu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="
                        $refs.proceedDateMenuRef.save(editItem.proceed_date)
                      "
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </validation-provider>
              <v-divider class="my-4"></v-divider>
            </section>
            <section v-if="projectPhase === 'Design'" class="mt-4">
              <div class="text-lg primary--text font-weight-semibold mb-4">
                Project Times
              </div>
              <!-- Day Start Time -->
              <validation-provider
                v-slot="{ errors }"
                name="Day Start Time"
                rules="required_if:proceed"
                vid="daystart"
              >
                <label
                  for="day-start-time-menu"
                  class="label-color font-weight-semibold mb-2 d-block"
                >
                  Day Start Time
                </label>
                <v-menu
                  ref="dayStartTimeMenuRef"
                  v-model="dayStartTimeMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      id="day-start-time-menu"
                      v-model="editItem.day_start_time"
                      readonly
                      v-bind="attrs"
                      dense
                      outlined
                      clearable
                      v-on="on"
                      :prepend-inner-icon="icons.mdiClockTimeThreeOutline"
                      :error-messages="errors"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="dayStartTimeMenu"
                    v-model="editItem.day_start_time"
                    color="primary"
                    full-width
                    ampm-in-title
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="dayStartTimeMenu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="
                        $refs.dayStartTimeMenuRef.save(editItem.day_start_time)
                      "
                    >
                      OK
                    </v-btn>
                  </v-time-picker>
                </v-menu>
              </validation-provider>

              <!-- Day End Time -->
              <validation-provider
                v-slot="{ errors }"
                name="Day End Time"
                rules="required_if:daystart"
              >
                <label
                  for="day-end-time-menu"
                  class="label-color font-weight-semibold mb-2 d-block"
                >
                  Day End Time
                </label>
                <v-menu
                  ref="dayEndTimeMenuRef"
                  v-model="dayEndTimeMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      id="day-end-time-menu"
                      v-model="editItem.day_end_time"
                      readonly
                      v-bind="attrs"
                      dense
                      outlined
                      clearable
                      v-on="on"
                      :prepend-inner-icon="icons.mdiClockTimeThreeOutline"
                      :error-messages="errors"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="dayEndTimeMenu"
                    v-model="editItem.day_end_time"
                    color="primary"
                    full-width
                    ampm-in-title
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="dayEndTimeMenu = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="
                        $refs.dayEndTimeMenuRef.save(editItem.day_end_time)
                      "
                    >
                      OK
                    </v-btn>
                  </v-time-picker>
                </v-menu>
              </validation-provider>

              <!-- Night Start Time -->
              <validation-provider
                v-slot="{ errors }"
                name="Night Start Time"
                rules=""
                vid="nightstart"
              >
                <label
                  for="night-start-time-menu"
                  class="label-color font-weight-semibold mb-2 d-block"
                >
                  Night Start Time
                </label>
                <v-menu
                  ref="nightStartTimeMenuRef"
                  v-model="nightStartTimeMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      id="night-start-time-menu"
                      v-model="editItem.night_start_time"
                      readonly
                      v-bind="attrs"
                      dense
                      outlined
                      clearable
                      v-on="on"
                      :prepend-inner-icon="icons.mdiClockTimeThreeOutline"
                      :error-messages="errors"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="nightStartTimeMenu"
                    v-model="editItem.night_start_time"
                    color="primary"
                    full-width
                    ampm-in-title
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="nightStartTimeMenu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="
                        $refs.nightStartTimeMenuRef.save(
                          editItem.night_start_time
                        )
                      "
                    >
                      OK
                    </v-btn>
                  </v-time-picker>
                </v-menu>
              </validation-provider>

              <!-- Night End Time -->
              <validation-provider
                v-slot="{ errors }"
                name="Night End Time"
                rules="required_if:nightstart"
              >
                <label
                  for="night-end-time-menu"
                  class="label-color font-weight-semibold mb-2 d-block"
                >
                  Night End Time
                </label>
                <v-menu
                  ref="nightEndTimeMenuRef"
                  v-model="nightEndTimeMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      id="night-end-time-menu"
                      v-model="editItem.night_end_time"
                      readonly
                      v-bind="attrs"
                      dense
                      outlined
                      clearable
                      v-on="on"
                      :prepend-inner-icon="icons.mdiClockTimeThreeOutline"
                      :error-messages="errors"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="nightEndTimeMenu"
                    v-model="editItem.night_end_time"
                    color="primary"
                    full-width
                    ampm-in-title
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="nightEndTimeMenu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="
                        $refs.nightEndTimeMenuRef.save(editItem.night_end_time)
                      "
                    >
                      OK
                    </v-btn>
                  </v-time-picker>
                </v-menu>
              </validation-provider>
            </section>
            <section v-if="projectPhase === 'Construction'">
              <div class="text-lg primary--text font-weight-semibold mb-4">
                Key Construction Dates
              </div>
              <!-- Start Date -->
              <validation-provider
                v-slot="{ errors }"
                name="Start Date"
                rules="required_if:closeout"
              >
                <label
                  for="start-date-menu"
                  class="label-color font-weight-semibold mb-2 d-block"
                >
                  Start Date
                </label>
                <v-menu
                  ref="startDateMenuRef"
                  v-model="startDateMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      id="start-date-menu"
                      v-model="editItem.start_date"
                      readonly
                      v-bind="attrs"
                      dense
                      outlined
                      clearable
                      v-on="on"
                      :prepend-inner-icon="icons.mdiCalendarMonth"
                      :error-messages="errors"
                    ></v-text-field>
                  </template>

                  <v-date-picker
                    v-model="editItem.start_date"
                    no-title
                    scrollable
                    @input="startDateMenu = false"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="startDateMenu = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.startDateMenuRef.save(editItem.start_date)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </validation-provider>

              <!-- Rain Start Date -->
              <validation-provider
                v-slot="{ errors }"
                name="Rain Start Date"
                rules=""
              >
                <label
                  for="rain-start-date-menu"
                  class="label-color font-weight-semibold mb-2 d-block"
                >
                  Rain Start Date
                </label>
                <v-menu
                  ref="rainStartDateMenuRef"
                  v-model="rainStartDateMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      id="rain-start-date-menu"
                      v-model="editItem.rain_start_date"
                      readonly
                      v-bind="attrs"
                      outlined
                      dense
                      clearable
                      v-on="on"
                      :prepend-inner-icon="icons.mdiCalendarMonth"
                      :error-messages="errors"
                    ></v-text-field>
                  </template>

                  <v-date-picker
                    v-model="editItem.rain_start_date"
                    :min="resolveMinRainStartDate(editItem)"
                    no-title
                    scrollable
                    @input="rainStartDateMenu = false"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="rainStartDateMenu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="
                        $refs.rainStartDateMenuRef.save(
                          editItem.rain_start_date
                        )
                      "
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </validation-provider>

              <!-- Actual Completion Date -->
              <validation-provider
                v-slot="{ errors }"
                mode="lazy"
                name="Completion Date"
                rules="required_if:closeout"
              >
                <label
                  for="end-date-menu"
                  class="label-color font-weight-semibold mb-2 d-block"
                >
                  Actual Completion Date
                </label>
                <v-menu
                  ref="endDateMenuRef"
                  v-model="endDateMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      id="end-date-menu"
                      v-model="editItem.end_date"
                      readonly
                      v-bind="attrs"
                      outlined
                      dense
                      clearable
                      v-on="on"
                      :prepend-inner-icon="icons.mdiCalendarMonth"
                      :error-messages="errors"
                    >
                    </v-text-field>
                  </template>

                  <v-date-picker
                    v-model="editItem.end_date"
                    no-title
                    scrollable
                    @input="endDateMenu = false"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="endDateMenu = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.startDateMenuRef.save(editItem.end_date)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </validation-provider>

              <!-- Close Out Date -->
              <validation-provider
                v-slot="{ errors }"
                name="Close Out Date"
                vid="closeout"
              >
                <label
                  for="close-out-date-menu"
                  class="label-color font-weight-semibold mb-2 d-block"
                >
                  Close Out Date
                </label>
                <v-menu
                  ref="closeOutDateMenuRef"
                  v-model="closeOutDateMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      id="close-out-date-menu"
                      v-model="editItem.close_out_date"
                      readonly
                      v-bind="attrs"
                      outlined
                      dense
                      clearable
                      v-on="on"
                      :prepend-inner-icon="icons.mdiCalendarMonth"
                      :error-messages="errors"
                    ></v-text-field>
                  </template>

                  <v-date-picker
                    v-model="editItem.close_out_date"
                    no-title
                    scrollable
                    @input="closeOutDateMenu = false"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="closeOutDateMenu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="
                        $refs.closeOutDateMenuRef.save(editItem.close_out_date)
                      "
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </validation-provider>
            </section>
          </form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text type="reset" color="secondary" small @click="onCancel">
            Cancel
          </v-btn>
          <v-btn
            type="submit"
            color="primary"
            small
            :disabled="invalid"
            @click="handleFormSubmit"
          >
            Save
          </v-btn>
        </v-card-actions>
        <v-overlay :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-card>
    </v-dialog>
  </validation-observer>
</template>
