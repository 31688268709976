<script>
  import { mdiPencil } from "@mdi/js";
  import { defineComponent } from "@vue/composition-api";

  import { useRouter } from "@/helpers";
  import ProjectActivityTimeline from "@/views/apps/project/project-view/project-activity-timeline/ProjectActivityTimeline.vue";
  import ProjectEditKeyDates from "@/views/apps/project/project-view/project-key-dates/ProjectEditKeyDates.vue";
  import ProjectKeyDates from "@/views/apps/project/project-view/project-key-dates/ProjectKeyDates.vue";
  import {
    getProjectFromStore,
    useProjectDialog
  } from "@/views/apps/project/useProject";
  // import { formatDate } from "@/helpers/filter.js";

  export default defineComponent({
    components: {
      ProjectKeyDates,
      ProjectActivityTimeline,
      ProjectEditKeyDates
    },
    // props: {
    //   projectId: {
    //     type: String,
    //     required: true
    //   }
    // },
    setup() {
      //const { project } = getProjectFromStore(props.projectId);
      //const projectId = props.projectId;
      const { route } = useRouter();
      const { projectRecord } = getProjectFromStore(route.value.params.project);
      const {
        isProjectKeyDatesDialogOpen,
        itemToEdit,
        editItem,
        processFormData,
        closeDialog
      } = useProjectDialog();

      return {
        projectRecord,
        isProjectKeyDatesDialogOpen,
        itemToEdit,
        editItem,
        processFormData,
        closeDialog,
        //projectId
        //project,
        icons: {
          mdiPencil
        }
        // formatDate
      };
    }
  });
</script>

<template>
  <div v-if="projectRecord">
    <v-card flat>
      <v-card-title class="d-flex justify-space-between mb-8">
        <div class="d-flex">Project Schedule</div>
        <div class="d-flex">
          <v-btn
            color="primary"
            class="me-3"
            x-small
            outlined
            @click="editItem(projectRecord, 'dates')"
          >
            <v-icon left>{{ icons.mdiPencil }}</v-icon>
            Edit
          </v-btn>
        </div>
      </v-card-title>
      <v-card-text>
        <project-key-dates :project-record="projectRecord" />
        <project-activity-timeline :project-record="projectRecord" />
      </v-card-text>
    </v-card>
    <!-- edit project key dates dialog data -->
    <project-edit-key-dates
      :isProjectKeyDatesDialogOpen.sync="isProjectKeyDatesDialogOpen"
      :edit-item="itemToEdit"
      :project-phase="projectRecord.project_phase"
      @save="processFormData"
      @close-dialog="closeDialog"
    ></project-edit-key-dates>
  </div>
</template>
