var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.project)?_c('v-dialog',{attrs:{"value":_vm.isPermitListEditDialogOpen,"persistent":"","max-width":"850px"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Assign Permits ")]),_c('v-divider'),_c('v-card-text',[_c('div',{staticClass:"d-flex justify-space-between flex-wrap flex-column flex-sm-row"},[_c('div',{staticClass:"pa-5 flex-grow-1"},[_c('v-row',{staticClass:"mb-5"},[_c('div',{staticClass:"text font-weight-medium"},[_vm._v(" Search for and select a permit from below to associate a permit with this project. ")])]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('label',{staticClass:"label-color font-weight-semibold mb-2 d-block",attrs:{"for":"permits"}},[_vm._v(" Permits Search ")]),(_vm.latestPermitsQuery)?_c('v-autocomplete',{attrs:{"id":"permits","items":_vm.latestPermitsQuery.response.data,"search-input":_vm.search,"loading":_vm.isLoading,"return-object":"","multiple":"","dense":"","hide-selected":"","item-text":"permit_id","item-value":"fulcrum_id","placeholder":"Search for permit(s) to associate with this project...","outlined":"","prepend-inner-icon":_vm.icons.mdiMagnify},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"input":function($event){return _vm.$emit('update', _vm.selectedPermits)}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',{staticClass:"my-3"},[_vm._v(" Associate "),_c('strong',[_vm._v(" permits ")]),_vm._v(" with this project ")])]},proxy:true},{key:"selection",fn:function(){return undefined},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',{staticClass:"headline font-weight-light",attrs:{"tile":"","size":"26","color":"transparent"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.permitTypeIcon(item.permit_type[0]))+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.permit_id)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.permit_desc)}})],1),_c('v-list-item-action',[_c('v-icon',{attrs:{"small":"","color":"#5DC174"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")])],1)]}}],null,false,187567853),model:{value:(_vm.selectedPermits),callback:function ($$v) {_vm.selectedPermits=$$v},expression:"selectedPermits"}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.editItems,"item-key":"permit_record","disable-sort":"","hide-default-footer":"","no-data-text":"No permits selected"},scopedSlots:_vm._u([{key:"item.permit_id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{class:item.permit_type
                          ? ''
                          : 'v-avatar-light-bg primary--text',attrs:{"color":item.permit_type ? '' : 'primary',"size":"32"}},[(item.permit_type)?_c('v-icon',[_vm._v(" "+_vm._s(_vm.permitTypeIcon(item.permit_type[0]))+" ")]):_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.avatarText(item.permit_num))+" ")])],1),_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('span',{staticClass:"text--primary font-weight-semibold"},[_vm._v(" "+_vm._s(item.permit_num)+" ")]),_c('small',[_vm._v(_vm._s(item.permit_desc))])])],1)]}},{key:"item.actions",fn:function(ref){
                          var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":"error"},on:{"click":function($event){return _vm.handlePermitRemoval(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiTrashCanOutline)+" ")])]}}],null,true)})],1)],1)],1)])]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"type":"reset","color":"secondary","text":"","small":""},on:{"click":_vm.onCancel}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"type":"submit","color":"primary","small":""},on:{"click":function($event){return _vm.handleFormSubmit(_vm.editItems, _vm.project)}}},[_vm._v(" Save ")])],1),(_vm.loading)?_c('v-overlay',{attrs:{"absolute":"","color":"white","opacity":"0.40","fill-height":""}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}},[_c('span',{staticClass:"mt-16"},[_vm._v("Saving...")])])],1):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }