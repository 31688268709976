import { computed } from "@vue/composition-api";
import { useFind } from "feathers-vuex";
import Vue from "vue";

import { findAllContracts } from "@/store/queries/contracts";

const { Contract } = Vue.$FeathersVuex.api;

export function useContractLookup() {
  const contractsParams = computed(findAllContracts);

  const {
    items: contracts,
    isPending: contractsArePending,
    haveLoaded: contractsHaveLoaded
  } = useFind({
    model: Contract,
    params: contractsParams.value,
    qid: "contractLookupList"
  });

  const activeConstructionContracts = computed(() => {
    return useActiveConstructionContracts(contracts);
  });

  function useActiveConstructionContracts(contracts) {
    return contracts.value.filter(
      (contract) =>
        contract.status === "Active" &&
        contract.contract_type === "Construction"
    );
  }

  return {
    contracts,
    activeConstructionContracts,
    contractsArePending,
    contractsHaveLoaded
  };
}
