<script>
  import {
    mdiCalculatorVariantOutline,
    mdiCurrencyUsd,
    mdiPencil,
    mdiPercentOutline
  } from "@mdi/js";
  import { defineComponent } from "@vue/composition-api";

  import { useRouter } from "@/helpers";
  import { formatCurrency, formatPercentage } from "@/helpers/filter";
  import ProjectBurndownChart from "@/views/apps/project/project-view/project-burndown-chart/ProjectBurndownChart.vue";
  import ProjectEditTabFinancial from "@/views/apps/project/project-view/project-tab-financial/ProjectEditTabFinancial.vue";

  import { getProjectFromStore, useProjectDialog } from "../../useProject.js";
  import { useProjectFinancial } from "./useProjectTabFinancial";

  export default defineComponent({
    components: {
      ProjectBurndownChart,
      ProjectEditTabFinancial
    },
    setup() {
      const { route } = useRouter();
      const { projectRecord } = getProjectFromStore(route.value.params.project);
      const {
        projectFinancial,
        projectFinancialHasLoaded,
        projectFinancialIsPending,
        resolvePercentBudgetColor
      } = useProjectFinancial();

      const {
        isProjectTabFinancialDialogOpen,
        itemToEdit,
        editItem,
        processFormData,
        closeDialog
      } = useProjectDialog();

      return {
        projectRecord,
        projectFinancial,
        projectFinancialHasLoaded,
        projectFinancialIsPending,
        resolvePercentBudgetColor,
        icons: {
          mdiCalculatorVariantOutline,
          mdiCurrencyUsd,
          mdiPercentOutline,
          mdiPencil
        },
        formatCurrency,
        formatPercentage,
        isProjectTabFinancialDialogOpen,
        itemToEdit,
        editItem,
        processFormData,
        closeDialog
      };
    }
  });
</script>

<template>
  <div>
    <v-card flat>
      <v-card-title class="align-start">
        <span class="font-weight-semibold">Project Financial Summary</span>
        <v-spacer></v-spacer>
        <v-btn
          v-if="projectRecord.project_phase === 'Design'"
          color="primary"
          class="me-3"
          x-small
          outlined
          @click="editItem(projectRecord, 'financial')"
        >
          <v-icon left>{{ icons.mdiPencil }}</v-icon>
          Edit
        </v-btn>
      </v-card-title>
      <div v-if="!projectFinancialIsPending">
        <v-card-subtitle v-if="projectRecord.contingency" class="mb-8 mt-n5">
          <span>This project has a </span>
          <span class="font-weight-semibold text--primary me-1">
            {{ projectRecord.contingency }}%
          </span>
          <span>contingency.</span>
        </v-card-subtitle>

        <v-card-text v-if="projectFinancialHasLoaded">
          <v-row>
            <v-col cols="6" md="3" class="d-flex align-center">
              <v-avatar size="44" color="info" rounded class="elevation-1">
                <v-icon dark color="white" size="30">
                  {{ icons.mdiCalculatorVariantOutline }}
                </v-icon>
              </v-avatar>
              <div class="ms-3">
                <p class="text-xs mb-0">Estimated Cost</p>
                <h3 class="text-xl font-weight-semibold">
                  {{ formatCurrency(projectFinancial.estimate_cost) }}
                </h3>
              </div>
            </v-col>
            <v-col cols="6" md="3" class="d-flex align-center">
              <v-avatar size="44" color="info" rounded class="elevation-1">
                <v-icon dark color="white" size="30">
                  {{ icons.mdiCurrencyUsd }}
                </v-icon>
              </v-avatar>
              <div class="ms-3">
                <p class="text-xs mb-0">Actual Cost</p>
                <h3 class="text-xl font-weight-semibold">
                  {{ formatCurrency(projectFinancial.actual_cost) }}
                </h3>
              </div>
            </v-col>
            <v-col cols="6" md="3" class="d-flex align-center">
              <v-avatar
                size="44"
                :color="
                  resolvePercentBudgetColor(projectFinancial.remaining_percent)
                "
                rounded
                class="elevation-1"
              >
                <v-icon dark color="white" size="30">
                  {{ icons.mdiCurrencyUsd }}
                </v-icon>
              </v-avatar>
              <div class="ms-3">
                <p class="text-xs mb-0">Remaining</p>
                <h3
                  class="text-xl font-weight-semibold"
                  :class="`${resolvePercentBudgetColor(
                    projectFinancial.remaining_percent
                  )}--text`"
                >
                  {{ formatCurrency(projectFinancial.remaining_cost) }}
                </h3>
              </div>
            </v-col>
            <v-col cols="6" md="3" class="d-flex align-center">
              <v-avatar
                size="44"
                :color="
                  resolvePercentBudgetColor(projectFinancial.remaining_percent)
                "
                rounded
                class="elevation-1"
              >
                <v-icon dark color="white" size="30">
                  {{ icons.mdiPercentOutline }}
                </v-icon>
              </v-avatar>
              <div class="ms-3">
                <p class="text-xs mb-0">Remaining</p>
                <h3
                  class="text-xl font-weight-semibold"
                  :class="`${resolvePercentBudgetColor(
                    projectFinancial.remaining_percent
                  )}--text`"
                >
                  {{ Math.round(projectFinancial.remaining_percent) }}%
                </h3>
              </div>
            </v-col>
          </v-row>
          <v-row
            v-if="projectRecord.project_phase === 'Construction'"
            class="mt-10"
          >
            <v-col cols="12">
              <project-burndown-chart />
            </v-col>
          </v-row>
        </v-card-text>
      </div>
      <div v-if="!projectFinancialHasLoaded && !projectFinancialIsPending">
        <v-card-text>
          <v-row>
            <v-col cols="12" class="d-flex justify-center">
              Nothing has been entered into the system.
            </v-col>
          </v-row>
        </v-card-text>
      </div>
    </v-card>
    <v-card v-if="projectFinancialIsPending" height="191">
      <v-overlay absolute color="white" opacity="0.75">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-overlay>
    </v-card>
    <project-edit-tab-financial
      :is-project-tab-financial-dialog-open.sync="
        isProjectTabFinancialDialogOpen
      "
      :edit-item="itemToEdit"
      @save="processFormData"
      @close-dialog="closeDialog"
    ></project-edit-tab-financial>
  </div>
</template>
