<script>
  import { mdiChevronLeft } from "@mdi/js";
  import { defineComponent, ref } from "@vue/composition-api";
  // Using VeeValidate for validations
  import { ValidationObserver, ValidationProvider } from "vee-validate";
  import Vue from "vue";

  import { snackbar } from "@/components/base/snackbar/useSnackbar";
  import {
    useDesignerContactsList,
    useLeadInspectorContactsList
  } from "@/composables/useContacts";
  import { useContractorListLookup } from "@/composables/useContractorLists";
  // Populate the dropdown components
  import { useFiscalYearList } from "@/composables/useFiscalYears";
  import { useMotRequirementList } from "@/composables/useMotRequirements";
  import {
    useContractorOrganizationList,
    useDesignOrganizationList
  } from "@/composables/useOrganizations";
  import { useProjectTypeList } from "@/composables/useProjectTypes";
  // Notification
  import { store } from "@/store";
  import { useAssignedDatePicker } from "@/views/apps/project/project-form-timeline/useProjectFormTimeline";
  import { useProjectDialog } from "@/views/apps/project/useProject";

  export default defineComponent({
    components: {
      ValidationProvider,
      ValidationObserver
    },
    props: {
      isProjectInfoDialogOpen: {
        type: Boolean,
        required: true
      },
      editItem: {
        type: Object,
        required: true
      }
    },
    setup(props, { emit }) {
      // Initialize the form
      // const activeStep = ref(1);
      const form = ref(null);

      const { activeStep, loading, resetForm } = useProjectDialog();
      const { Contact } = Vue.$FeathersVuex.api;

      // Populate the dropdown components
      const { fiscalYears } = useFiscalYearList();
      const { projectTypes } = useProjectTypeList();
      const { motRequirements } = useMotRequirementList();
      const { designFirms } = useDesignOrganizationList();
      const { contractorOrganizations } = useContractorOrganizationList();
      const { distinctActiveContractorLists } = useContractorListLookup();
      const { designerContacts } = useDesignerContactsList();
      const { assignedDate, assignedDateMenu } = useAssignedDatePicker();
      const { leadInspectorContacts } = useLeadInspectorContactsList();

      // form submit
      const handleFormSubmit = async () => {
        try {
          store.dispatch("projects/updateLoading", true);
          await handlePopulateDesignFirm(props.editItem);
          emit("save", props.editItem);
          form.value.reset();
        } catch (error) {
          snackbar({
            text: `Unable to submit form. Please contact your Administrator.`,
            color: "error"
          });
        }
      };

      // before we leave the form let's make sure the design firm
      // value is set for the project if a designer was selected.
      async function handlePopulateDesignFirm(editItem) {
        // if the designer is set to `null`, set the design firm to `null`
        if (editItem.designer_record == null) {
          editItem.design_firm_record = null;
        }
        // if the project has a designer, populate the `design_firm_record` field
        // we do this even if the user has already selected a designer in order
        // to catch changes
        if (editItem.designer_record !== null) {
          await Contact.get(editItem.designer_record).then((contact) => {
            editItem.design_firm_record = contact.org_id;
          });
        }
      }

      // form cancel
      const onCancel = () => {
        form.value.reset();
        emit("close-dialog", false);

        // reset the activeStep so the dialog will open on step 1
        activeStep.value = 1;
      };

      return {
        activeStep,
        loading,
        form,
        handleFormSubmit,
        onCancel,
        resetForm,
        fiscalYears,
        projectTypes,
        motRequirements,
        designFirms,
        contractorOrganizations,
        distinctActiveContractorLists,
        designerContacts,
        assignedDate,
        assignedDateMenu,
        leadInspectorContacts,
        icons: {
          mdiChevronLeft
        }
      };
    }
  });
</script>

<template>
  <validation-observer ref="form" v-slot="{ invalid, reset }">
    <!-- dialog -->
    <v-dialog
      :value="isProjectInfoDialogOpen"
      scrollable
      persistent
      max-width="850px"
    >
      <v-card>
        <v-card-title> Edit Project Details </v-card-title>
        <v-card-text>
          <form @submit.prevent="handleFormSubmit" @reset.prevent="reset">
            <v-stepper
              v-model="activeStep"
              class="custom-header"
              alt-labels
              flat
            >
              <v-stepper-header>
                <!-- Header: Step 1 -->
                <v-stepper-step :complete="activeStep > 1" step="1">
                  <div class="d-flex align-center">
                    <!-- <span class="text--primary text-4xl font-weight-bold me-3">
                    01
                  </span> -->
                    <div class="d-flex flex-column">
                      <span class="text--primary text-sm font-weight-semibold">
                        Project Details
                      </span>
                    </div>
                  </div>
                </v-stepper-step>
                <v-divider></v-divider>
                <!-- Header: Step 2 -->
                <v-stepper-step :complete="activeStep > 2" step="2">
                  <div class="d-flex align-center">
                    <!-- <span class="text--primary text-4xl font-weight-bold me-3">
                    02
                  </span> -->
                    <div class="d-flex flex-column">
                      <span class="text--primary text-sm font-weight-semibold">
                        Additional Details
                      </span>
                      <span class="text--secondary text-xs"> Optional </span>
                    </div>
                  </div>
                </v-stepper-step>
                <v-divider></v-divider>
                <!-- Header: Step 3 -->
                <v-stepper-step :complete="activeStep > 3" step="3">
                  <div class="d-flex align-center">
                    <!-- <span class="text--primary text-4xl font-weight-bold me-3">
                    03
                  </span> -->
                    <div class="d-flex flex-column">
                      <span class="text--primary text-sm font-weight-semibold">
                        Key Personnel
                      </span>
                    </div>
                  </div>
                </v-stepper-step>
              </v-stepper-header>
              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-row>
                    <v-col cols="12">
                      <!-- Proceed Name -->
                      <validation-provider
                        v-slot="{ errors }"
                        name="Proceed Name"
                        rules="required"
                      >
                        <label
                          for="proceed-name"
                          class="label-color font-weight-semibold mb-2 d-block"
                        >
                          Proceed Name<span style="color: red">*</span>
                        </label>
                        <v-text-field
                          id="proceed-name"
                          v-model="editItem.proceed_name"
                          outlined
                          clearable
                          dense
                          :error-messages="errors"
                          @input="$emit('update:modelValue', $event)"
                        />
                      </validation-provider>

                      <!-- Design Description -->
                      <validation-provider
                        v-slot="{ errors }"
                        name="Design Description"
                        rules="required|max:1000"
                      >
                        <label
                          for="design-desc"
                          class="label-color font-weight-semibold mb-2 d-block"
                        >
                          Design Description (for Press Release)<span
                            style="color: red"
                            >*</span
                          >
                        </label>
                        <v-textarea
                          id="design-desc"
                          v-model="editItem.design_desc"
                          outlined
                          clearable
                          dense
                          :error-messages="errors"
                          @input="$emit('update:modelValue', $event)"
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="3">
                      <!-- Fiscal Year -->
                      <label
                        for="fiscal-year"
                        class="label-color font-weight-semibold mb-2 d-block"
                      >
                        Fiscal Year
                      </label>
                      <v-select
                        id="fiscal-year"
                        v-model="editItem.fiscal_year"
                        outlined
                        dense
                        :items="fiscalYears"
                        item-value="display_year"
                        item-text="display_year"
                        clearable
                        @input="$emit('update:modelValue', $event)"
                      />
                    </v-col>
                    <v-col cols="12" md="4">
                      <!-- Project Type -->
                      <label
                        for="project-type"
                        class="label-color font-weight-semibold mb-2 d-block"
                      >
                        Project Type
                      </label>
                      <v-select
                        id="project-type"
                        v-model="editItem.project_type"
                        outlined
                        dense
                        :items="projectTypes"
                        item-value="display_type"
                        item-text="display_type"
                        clearable
                        @input="$emit('update:modelValue', $event)"
                      />
                    </v-col>
                    <v-col cols="12" md="5">
                      <!-- MOT Requirements -->
                      <label
                        for="mot-req"
                        class="label-color font-weight-semibold mb-2 d-block"
                      >
                        MOT Requirements
                      </label>
                      <v-select
                        id="mot-req"
                        v-model="editItem.mot_req"
                        outlined
                        dense
                        :items="motRequirements"
                        item-value="display_status"
                        item-text="display_status"
                        clearable
                        @input="$emit('update:modelValue', $event)"
                      />
                    </v-col>
                  </v-row>
                </v-stepper-content>

                <v-stepper-content step="2">
                  <!-- Short Description / Scope of Work -->
                  <validation-provider
                    v-slot="{ errors }"
                    name="Design Description"
                    rules="max:1000"
                  >
                    <label
                      for="proceed-desc"
                      class="label-color font-weight-semibold mb-2 d-block"
                    >
                      Short Description/Scope of Work (for Internal Use)
                    </label>
                    <v-textarea
                      id="proceed-desc"
                      v-model="editItem.proceed_desc"
                      outlined
                      clearable
                      auto-grow
                      rows="16"
                      row-height="20"
                      dense
                      :error-messages="errors"
                      @input="$emit('update:modelValue', $event)"
                    />
                  </validation-provider>
                </v-stepper-content>

                <v-stepper-content step="3">
                  <v-row>
                    <v-col cols="12" md="6">
                      <!-- Designer -->
                      <!-- TODO: Update the values based on the Design Firm selected -->
                      <validation-provider
                        v-slot="{ errors }"
                        name="Designer"
                        rules=""
                        vid="designer"
                      >
                        <label
                          for="designer"
                          class="label-color font-weight-semibold mb-2 d-block"
                        >
                          Designer
                        </label>
                        <v-select
                          id="designer"
                          v-model="editItem.designer_record"
                          outlined
                          dense
                          :items="designerContacts"
                          item-value="fulcrum_id"
                          clearable
                          @input="$emit('update:modelValue', $event)"
                        >
                          <!-- Template slot for the dropdown -->
                          <template slot="item" slot-scope="data">
                            <v-list-item-content dense>
                              <v-list-item-title>
                                {{ data.item.first_name }}
                                {{ data.item.last_name }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </template>

                          <!-- Template slot for the selected value -->
                          <template v-slot:selection="{ item }">
                            {{ item.first_name }} {{ item.last_name }}
                          </template>
                        </v-select>
                      </validation-provider>
                    </v-col>

                    <v-col cols="12" md="6">
                      <!-- Assigned to Designer Date -->
                      <validation-provider
                        v-slot="{ errors }"
                        name="Assigned Date"
                        rules=""
                      >
                        <label
                          for="assigned-date-menu"
                          class="label-color font-weight-semibold mb-2 d-block"
                        >
                          Date Assigned to Designer
                        </label>
                        <v-menu
                          ref="assignedDateMenuRef"
                          v-model="assignedDateMenu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              id="assigned-date-menu"
                              v-model="editItem.assign_date"
                              readonly
                              v-bind="attrs"
                              dense
                              outlined
                              clearable
                              v-on="on"
                              :prepend-inner-icon="icons.mdiCalendarMonth"
                              :error-messages="errors"
                            ></v-text-field>
                          </template>

                          <v-date-picker
                            v-model="editItem.assign_date"
                            no-title
                            scrollable
                            @input="assignedDateMenu = false"
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="assignedDateMenu = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="
                                $refs.assignedDateMenuRef.save(
                                  editItem.assign_date
                                )
                              "
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" md="12">
                      <!-- Prime Contractor -->
                      <label
                        for="contractor"
                        class="label-color font-weight-semibold mb-2 d-block"
                      >
                        Prime Contractor
                      </label>
                      <v-select
                        id="contractor"
                        v-model="editItem.contractor_record"
                        outlined
                        :items="distinctActiveContractorLists"
                        item-value="contractor_record"
                        item-text="contractor_name"
                        dense
                        clearable
                        @input="$emit('update:modelValue', $event)"
                      ></v-select>

                      <!-- Lead Inspector -->
                      <label
                        for="inspector"
                        class="label-color font-weight-semibold mb-2 d-block"
                      >
                        Lead Inspector
                      </label>
                      <v-select
                        id="inspector"
                        v-model="editItem.inspector_record"
                        outlined
                        :items="leadInspectorContacts"
                        item-value="fulcrum_id"
                        item-text="fullName"
                        dense
                        clearable
                        @input="$emit('update:modelValue', $event)"
                      >
                        <!-- Template slot for the dropdown -->
                        <template slot="item" slot-scope="data">
                          <v-list-item-content dense>
                            <v-list-item-title>
                              {{ data.item.first_name }}
                              {{ data.item.last_name }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </template>

                        <!-- Template slot for the selected value -->
                        <template v-slot:selection="{ item }">
                          {{ item.first_name }} {{ item.last_name }}
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            v-if="activeStep > 1"
            text
            color="primary"
            class="me-2"
            small
            @click="activeStep -= 1"
          >
            <v-icon>
              {{ icons.mdiChevronLeft }}
            </v-icon>
            Back
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn text type="reset" color="secondary" small @click="onCancel">
            Cancel
          </v-btn>
          <v-btn
            v-if="activeStep < 3"
            text
            color="primary"
            class="me-2"
            small
            @click="activeStep += 1"
          >
            Continue
          </v-btn>
          <v-btn
            v-show="activeStep == 3"
            type="submit"
            color="primary"
            small
            :loading="loading"
            :disabled="invalid || loading"
            @click="handleFormSubmit"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </validation-observer>
</template>
