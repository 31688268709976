import { computed } from "@vue/composition-api";
import { useFind } from "feathers-vuex";
import Vue from "vue";

import { findAllShortLists } from "@/store/queries/short-lists";

const { ShortList } = Vue.$FeathersVuex.api;

export function useShortListLookup() {
  const shortListsParams = computed(findAllShortLists);

  const {
    items: shortLists,
    isPending: shortListsArePending,
    haveLoaded: shortListsHaveLoaded
  } = useFind({
    model: ShortList,
    params: shortListsParams.value,
    qid: "shortListLookupList"
  });

  return {
    shortLists,
    shortListsArePending,
    shortListsHaveLoaded
  };
}
