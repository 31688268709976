<script>
  import {
    mdiAccountHardHatOutline,
    mdiBriefcaseVariantOutline,
    mdiBulldozer,
    mdiCalendarBlankOutline,
    mdiCheck,
    mdiCheckboxBlankCircle,
    mdiFileSign,
    mdiFlagVariantOutline,
    mdiOfficeBuildingOutline,
    mdiPencil,
    mdiPencilRuler
  } from "@mdi/js";
  import { defineComponent } from "@vue/composition-api";

  import { resolveOrganizationLogo } from "@/composables/useOrganizations";
  import { avatarText, formatDate } from "@/helpers/filter";
  import { projectStatusColor } from "@/helpers/statusColors";
  import useVuetify from "@/helpers/vuetify";
  import { useProjectDialog } from "@/views/apps/project/useProject";

  import ProjectEditInfoPanel from "./ProjectEditInfoPanel.vue";

  export default defineComponent({
    components: {
      ProjectEditInfoPanel
    },
    props: {
      projectData: {
        type: Object,
        required: true
      }
    },
    setup() {
      const { rootThemeClasses } = useVuetify();
      const {
        isProjectInfoDialogOpen,
        itemToEdit,
        editItem,
        processFormData,
        closeDialog
      } = useProjectDialog();

      return {
        rootThemeClasses,
        isProjectInfoDialogOpen,
        itemToEdit,
        editItem,
        processFormData,
        closeDialog,
        resolveOrganizationLogo,
        avatarText,
        formatDate,
        projectStatusColor,
        icons: {
          mdiCheck,
          mdiBriefcaseVariantOutline,
          mdiCheckboxBlankCircle,
          mdiPencil,
          mdiPencilRuler,
          mdiBulldozer,
          mdiAccountHardHatOutline,
          mdiFlagVariantOutline,
          mdiFileSign,
          mdiCalendarBlankOutline,
          mdiOfficeBuildingOutline
        }
      };
    }
  });
</script>

<template>
  <v-row class="project-info-panel">
    <!-- project summary -->
    <v-col cols="12">
      <v-card flat class="pt-10">
        <v-card-title class="justify-center flex-column">
          <v-avatar
            :color="projectData.design_firm_record ? '' : 'primary'"
            :class="
              projectData.design_firm_record
                ? ''
                : 'v-avatar-light-bg primary--text'
            "
            size="120"
            rounded
            class="mb-4"
          >
            <v-img
              v-if="projectData.design_firm_record"
              :src="resolveOrganizationLogo(projectData.contractor_record)"
            ></v-img>
            <span v-else class="font-weight-semibold text-5xl">{{
              avatarText(projectData.contractor_nm)
            }}</span>
          </v-avatar>
          <span class="my-2 primary--text">{{ projectData.proceed_num }}</span>
          <!-- <span class="mb-2 text-wrap">{{ projectData.proceed_name }}</span> -->

          <v-chip
            v-if="projectData.project_type"
            label
            :class="`v-chip-light-bg text-sm font-weight-semibold my-2`"
          >
            {{ projectData.project_type }}
          </v-chip>
        </v-card-title>

        <!-- Short Description / Scope of Work -->
        <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-0">Scope of Work</h2>
          <v-divider></v-divider>
          <v-list>
            <v-list-item dense class="px-0 mb-3">
              <span v-if="projectData.proceed_desc" class="font-weight-medium">
                {{ projectData.proceed_desc }}
              </span>
              <span v-else class="font-weight-medium">
                Scope of Work not provided.
              </span>
            </v-list-item>
          </v-list>
        </v-card-text>

        <!-- Design Description -->
        <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-0">Design Description</h2>
          <v-divider></v-divider>
          <v-list>
            <v-list-item dense class="px-0 mb-3">
              <span v-if="projectData.design_desc" class="font-weight-medium">
                {{ projectData.design_desc }}
              </span>
              <span v-else class="font-weight-medium">
                No description provided.
              </span>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-0">Details</h2>
          <v-divider></v-divider>

          <v-list>
            <!-- Designer -->
            <v-list-item dense class="px-0 mb-n2">
              <v-list-item-avatar class="ma-0">
                <v-icon small>{{ icons.mdiPencilRuler }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>Designer:</v-list-item-content>
              <v-list-item-content>
                <span class="font-weight-medium text-capitalize">
                  {{ projectData.designer_nm }}
                </span>
              </v-list-item-content>
            </v-list-item>

            <!-- Design Firm -->
            <v-list-item dense class="px-0 mb-n2">
              <v-list-item-avatar class="ma-0">
                <v-icon small>{{ icons.mdiOfficeBuildingOutline }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>Design Firm:</v-list-item-content>
              <v-list-item-content>
                <span class="font-weight-medium text-capitalize">
                  {{ projectData.design_firm_nm }}
                </span>
              </v-list-item-content>
            </v-list-item>

            <!-- Contractor -->
            <v-list-item dense class="px-0 mb-n2">
              <v-list-item-avatar class="ma-0">
                <v-icon small>{{ icons.mdiBulldozer }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content> Contractor: </v-list-item-content>
              <v-list-item-content>
                <span class="font-weight-medium text-capitalize">
                  {{ projectData.contractor_nm }}
                </span>
              </v-list-item-content>
            </v-list-item>

            <!-- Lead Inspector -->
            <v-list-item dense class="px-0 mb-n2">
              <v-list-item-avatar class="ma-0">
                <v-icon small>{{ icons.mdiAccountHardHatOutline }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content> Lead Inspector: </v-list-item-content>
              <v-list-item-content>
                <span class="font-weight-medium text-capitalize">
                  {{ projectData.inspector_nm }}
                </span>
              </v-list-item-content>
            </v-list-item>

            <!-- Project Status -->
            <v-list-item dense class="px-0 mb-n2">
              <v-list-item-avatar class="ma-0">
                <v-icon small>{{ icons.mdiFlagVariantOutline }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content> Status: </v-list-item-content>
              <v-list-item-content>
                <span class="font-weight-medium text--secondary">
                  <v-chip
                    small
                    label
                    :color="projectStatusColor(projectData.status)"
                    :class="`v-chip-light-bg ${projectStatusColor(
                      projectData.status
                    )}--text font-weight-medium text-capitalize`"
                  >
                    {{ projectData.status }}
                  </v-chip>
                </span>
              </v-list-item-content>
            </v-list-item>

            <!-- Contract Number -->
            <v-list-item dense class="px-0 mb-n2">
              <v-list-item-avatar class="ma-0">
                <v-icon small>{{ icons.mdiFileSign }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content> Contract #: </v-list-item-content>
              <v-list-item-content>
                <span class="font-weight-medium text-capitalize">
                  {{ projectData.contract_num }}
                </span>
              </v-list-item-content>
            </v-list-item>

            <!-- Approval Year -->
            <v-list-item dense class="px-0 mb-n2">
              <v-list-item-avatar class="ma-0">
                <v-icon small>{{ icons.mdiCalendarBlankOutline }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content> Approval Year: </v-list-item-content>
              <v-list-item-content>
                <span class="font-weight-medium text-capitalize">
                  {{ projectData.fiscal_year }}
                </span>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-actions
          v-if="projectData.project_phase === 'Design'"
          class="justify-center"
        >
          <v-btn
            color="primary"
            class="me-3"
            x-small
            outlined
            @click="editItem(projectData, 'details')"
          >
            <v-icon left>{{ icons.mdiPencil }}</v-icon>
            Edit
          </v-btn>
        </v-card-actions>
      </v-card>

      <!-- edit project dialog data -->
      <project-edit-info-panel
        :is-project-info-dialog-open.sync="isProjectInfoDialogOpen"
        :edit-item="itemToEdit"
        @save="processFormData(itemToEdit)"
        @close-dialog="closeDialog"
      ></project-edit-info-panel>
    </v-col>
  </v-row>
</template>
