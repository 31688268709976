<script>
  import {
    mdiCalendarClockOutline,
    mdiCalendarMonthOutline,
    mdiWeatherCloudyClock,
    mdiWeatherNight,
    mdiWeatherSunny
  } from "@mdi/js";
  import { defineComponent } from "@vue/composition-api";

  import { useRouter } from "@/helpers";
  import { formatDate } from "@/helpers/filter.js";

  import { getProjectFromStore } from "../../useProject.js";

  export default defineComponent({
    setup() {
      const { route } = useRouter();
      const { projectRecord } = getProjectFromStore(route.value.params.project);

      return {
        projectRecord,
        icons: {
          mdiCalendarClockOutline,
          mdiCalendarMonthOutline,
          mdiWeatherCloudyClock,
          mdiWeatherNight,
          mdiWeatherSunny
        },
        formatDate
      };
    }
  });
</script>

<template>
  <div v-if="projectRecord">
    <!-- Key Dates -->
    <v-row>
      <v-col cols="12" md="4">
        <!-- Estimated Start Date -->
        <v-card outlined>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <div class="d-flex">
                  <v-avatar color="info" size="38" class="elevation-3">
                    <v-icon size="24" color="white" class="rounded-0">
                      {{ icons.mdiCalendarMonthOutline }}
                    </v-icon>
                  </v-avatar>
                  <div class="ms-3">
                    <p class="secondary--text text-xs font-weight-bold mb-0">
                      Estimated Start
                    </p>
                    <span
                      v-if="projectRecord.estimate_start_date"
                      class="primary--text text-xl font-weight-bold"
                    >
                      {{ formatDate(projectRecord.estimate_start_date) }}
                    </span>
                    <span v-else class="error--text text-xl font-weight-bold">
                      ???
                    </span>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <!-- Estimated End Date -->
        <v-card outlined>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <div class="d-flex">
                  <v-avatar color="info" size="38" class="elevation-3">
                    <v-icon size="24" color="white" class="rounded-0">
                      {{ icons.mdiCalendarMonthOutline }}
                    </v-icon>
                  </v-avatar>
                  <div class="ms-3">
                    <p class="secondary--text text-xs font-weight-bold mb-0">
                      Estimated End
                    </p>
                    <span
                      v-if="projectRecord.estimate_end_date"
                      class="primary--text text-xl font-weight-bold"
                    >
                      {{ formatDate(projectRecord.estimate_end_date) }}
                    </span>
                    <span v-else class="error--text text-xl font-weight-bold">
                      ???
                    </span>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <!-- Hand Off to NTP Days -->
        <v-card outlined>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <div class="d-flex">
                  <v-avatar color="info" size="38" class="elevation-3">
                    <v-icon size="24" color="white" class="rounded-0">
                      {{ icons.mdiCalendarClockOutline }}
                    </v-icon>
                  </v-avatar>
                  <div class="ms-3">
                    <p class="secondary--text text-xs font-weight-bold mb-0">
                      Hand Off to NTP
                    </p>
                    <span
                      v-if="projectRecord.calc_hand_off_ntp >= 0"
                      class="primary--text text-xl font-weight-bold"
                    >
                      {{ projectRecord.calc_hand_off_ntp }}
                    </span>
                    <span v-else class="error--text text-xl font-weight-bold">
                      ???
                    </span>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Key Times -->
    <v-row>
      <v-col cols="12" md="4">
        <!-- Day Start/End Times -->
        <v-card outlined>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="4">
                <div class="d-flex">
                  <v-avatar color="warning" size="38" class="elevation-3">
                    <v-icon size="24" color="white" class="rounded-0">
                      {{ icons.mdiWeatherSunny }}
                    </v-icon>
                  </v-avatar>
                  <div class="ms-3">
                    <p class="secondary--text text-xs font-weight-bold mb-0">
                      Start
                    </p>
                    <span
                      v-if="projectRecord.day_start_time"
                      class="primary--text text-xl font-weight-bold"
                    >
                      {{ projectRecord.day_start_time }}
                    </span>
                    <span v-else class="error--text text-xl font-weight-bold">
                      ???
                    </span>
                  </div>
                  <v-divider
                    v-if="$vuetify.breakpoint.smAndUp"
                    vertical
                    inset
                    class="mx-3"
                  ></v-divider>
                  <div class="ms-3">
                    <p class="secondary--text text-xs font-weight-bold mb-0">
                      End
                    </p>
                    <span
                      v-if="projectRecord.day_end_time"
                      class="primary--text text-xl font-weight-bold"
                    >
                      {{ projectRecord.day_end_time }}
                    </span>
                    <span v-else class="error--text text-xl font-weight-bold">
                      ???
                    </span>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <!-- Night Start/End Times -->
        <v-card outlined>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="4">
                <div class="d-flex">
                  <v-avatar color="black" size="38" class="elevation-3">
                    <v-icon size="24" color="white" class="rounded-0">
                      {{ icons.mdiWeatherNight }}
                    </v-icon>
                  </v-avatar>
                  <div class="ms-3">
                    <p class="secondary--text text-xs font-weight-bold mb-0">
                      Start
                    </p>
                    <span
                      v-if="projectRecord.night_start_time"
                      class="primary--text text-xl font-weight-bold"
                    >
                      {{ projectRecord.night_start_time }}
                    </span>
                    <span v-else class="error--text text-xl font-weight-bold">
                      ???
                    </span>
                  </div>
                  <v-divider
                    v-if="$vuetify.breakpoint.smAndUp"
                    vertical
                    inset
                    class="mx-3"
                  ></v-divider>
                  <div class="ms-3">
                    <p class="secondary--text text-xs font-weight-bold mb-0">
                      End
                    </p>
                    <span
                      v-if="projectRecord.night_end_time"
                      class="primary--text text-xl font-weight-bold"
                    >
                      {{ projectRecord.night_end_time }}
                    </span>
                    <span v-else class="error--text text-xl font-weight-bold">
                      ???
                    </span>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <!-- Duration Days -->
        <v-card outlined>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <div class="d-flex">
                  <v-avatar color="info" size="38" class="elevation-3">
                    <v-icon size="24" color="white" class="rounded-0">
                      {{ icons.mdiCalendarClockOutline }}
                    </v-icon>
                  </v-avatar>
                  <div class="ms-3">
                    <p class="secondary--text text-xs font-weight-bold mb-0">
                      Allowable Working Days
                    </p>
                    <span
                      v-if="projectRecord.duration_days"
                      class="primary--text text-xl font-weight-bold"
                    >
                      {{ projectRecord.duration_days }}
                    </span>
                    <span v-else class="error--text text-xl font-weight-bold">
                      ???
                    </span>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
