<script>
  import { mdiAlertOutline, mdiCheckBold } from "@mdi/js";
  import { computed, defineComponent } from "@vue/composition-api";

  import { useRouter } from "@/helpers";

  import { resolveMissingNtpFields } from "../../useProject";
  import { useTsStatusBar } from "./useProjectStatusBar";

  export default defineComponent({
    props: {
      projectStatus: {
        type: String,
        required: true
      }
    },
    setup(props) {
      const { route } = useRouter();
      const { tsStatuses, activeStep } = useTsStatusBar(props.projectStatus);
      const { ntp, ntpHasLoaded } = resolveMissingNtpFields(
        route.value.params.project
      );
      const informationIsComplete = computed(() => {
        // if the ntp has loaded and there are no missing fields, then the information is complete
        // the ntp could be an array or it could be null
        if (ntpHasLoaded && ntp.value.missing_field == null) {
          return true;
        } else if (ntpHasLoaded && ntp.value.missing_field.length == 0) {
          return true;
        } else {
          return false;
        }
      });

      const showMissingDataDialog = () => {
        console.log("showMissingDataDialog");
      };

      return {
        tsStatuses,
        activeStep,
        informationIsComplete,
        showMissingDataDialog,
        icons: {
          mdiCheckBold,
          mdiAlertOutline
        },
        ntpHasLoaded,
        ntp
      };
    }
  });
</script>

<template>
  <v-stepper
    v-if="ntpHasLoaded"
    v-model="activeStep"
    flat
    class="custom-header"
    alt-labels
  >
    <!-- Header -->
    <v-stepper-header>
      <!-- Header: Step 1 -->
      <v-stepper-step
        :complete="activeStep > 1"
        step="1"
        color="primary"
        :complete-icon="icons.mdiCheckBold"
      >
        <div class="d-flex flex-column text-center text-no-wrap">
          <span
            class="text-sm font-weight-semibold"
            :class="activeStep > 1 ? 'primary--text' : 'black--text'"
          >
            Not<br />Started
          </span>
        </div>
      </v-stepper-step>

      <v-divider></v-divider>

      <!-- Header: Step 2 -->
      <v-stepper-step
        :complete="activeStep > 2"
        step="2"
        color="primary"
        :complete-icon="icons.mdiCheckBold"
      >
        <div class="d-flex flex-column text-center text-no-wrap">
          <span
            class="text-sm font-weight-semibold"
            :class="activeStep > 2 ? 'primary--text' : 'black--text'"
          >
            In<br />Progress
          </span>
        </div>
      </v-stepper-step>

      <v-divider></v-divider>

      <!-- Header: Step 3 -->
      <v-stepper-step
        :complete="activeStep > 3"
        step="3"
        color="primary"
        :complete-icon="icons.mdiCheckBold"
      >
        <div class="d-flex flex-column text-center text-no-wrap">
          <span
            class="text-sm font-weight-semibold"
            :class="activeStep > 3 ? 'primary--text' : 'black--text'"
          >
            Submitted
          </span>
        </div>
      </v-stepper-step>

      <v-divider></v-divider>

      <!-- Header: Step 4 -->
      <v-stepper-step
        :complete="activeStep > 4"
        step="4"
        color="primary"
        :complete-icon="icons.mdiCheckBold"
        :rules="[(v) => projectStatus !== 'TS Rejected']"
        :error-icon="icons.mdiAlertOutline"
      >
        <div class="d-flex flex-column text-center text-no-wrap">
          <span
            class="text-sm font-weight-semibold"
            :class="activeStep > 4 ? 'error--text' : 'error--text'"
            v-if="projectStatus === 'TS Rejected'"
          >
            Rejected
          </span>
          <span
            class="text-sm font-weight-semibold"
            :class="activeStep > 4 ? 'primary--text' : 'black--text'"
            v-else
          >
            Reviewed
          </span>
        </div>
      </v-stepper-step>

      <v-divider></v-divider>

      <!-- Header: Step 5 -->
      <v-stepper-step
        :complete="activeStep > 5"
        step="5"
        color="primary"
        :complete-icon="icons.mdiCheckBold"
      >
        <div class="d-flex flex-column text-center text-no-wrap">
          <span
            class="text-sm font-weight-semibold"
            :class="activeStep > 5 ? 'primary--text' : 'black--text'"
          >
            Accepted
          </span>
        </div>
      </v-stepper-step>

      <v-divider></v-divider>

      <!-- Header: Step 6 -->
      <v-stepper-step
        :complete="activeStep > 6 && informationIsComplete"
        step="6"
        color="primary"
        :complete-icon="icons.mdiCheckBold"
        :rules="[
          (v) =>
            projectStatus === 'TS Ok\'d for Distro' &&
            informationIsComplete === true
        ]"
        :error-icon="icons.mdiAlertOutline"
      >
        <div class="d-flex flex-column text-center text-no-wrap">
          <span
            class="text-sm font-weight-semibold"
            :class="activeStep > 6 ? 'primary--text' : 'error--text'"
            v-if="informationIsComplete === true"
          >
            NTP<br />Ready
          </span>
          <span
            class="text-sm font-weight-semibold"
            :class="!informationIsComplete ? 'error--text' : 'black--text'"
            v-else
            @click="showMissingDataDialog"
          >
            Required Data<br />Missing
          </span>
        </div>
      </v-stepper-step>

      <v-divider></v-divider>

      <!-- Header: Step 7 -->
      <v-stepper-step
        :complete="activeStep > 7"
        step="7"
        color="primary"
        :complete-icon="icons.mdiCheckBold"
      >
        <div class="d-flex flex-column text-center text-no-wrap">
          <span
            class="text-sm font-weight-semibold"
            :class="activeStep > 7 ? 'primary--text' : 'black--text'"
          >
            NTP
          </span>
        </div>
      </v-stepper-step>
    </v-stepper-header>
  </v-stepper>
</template>
