import { useGet } from "feathers-vuex";
import Vue from "vue";

import { useRouter } from "@/helpers";
const { ProjectFinancial } = Vue.$FeathersVuex.api;

export function useProjectFinancial() {
  const { route } = useRouter();

  const {
    item: projectFinancial,
    hasLoaded: projectFinancialHasLoaded,
    isPending: projectFinancialIsPending
  } = useGet({
    model: ProjectFinancial,
    id: route.value.params.project
  });

  const resolvePercentBudgetColor = (percent) => {
    if (percent < 0) {
      return "error";
    } else if (percent >= 0 && percent <= 25) {
      return "warning";
    } else {
      return "success";
    }
  };
  return {
    projectFinancial,
    projectFinancialHasLoaded,
    projectFinancialIsPending,
    resolvePercentBudgetColor
  };
}
