var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    (_vm.ntpHasLoaded &&
      _vm.projectRecord.project_phase === 'Design' &&
      _vm.ntp.missing_field !== null) ||
    (_vm.projectRecord.project_phase === 'Construction' &&
      _vm.closeOutHasLoaded &&
      _vm.closeOut.missing_field !== null)
  )?_c('v-alert',{attrs:{"dense":"","text":"","color":"error","border":"right","icon":_vm.icons.mdiAlertOutline,"transition":"slide-x-transition"}},[(
      _vm.projectRecord.project_phase === 'Design' && _vm.ntp.missing_field !== null
    )?_c('div',[_c('span',[_vm._v(" The following information is required "),_c('strong',[_vm._v("before")]),_vm._v(" the NTP can be distributed: ")]),_c('v-list',{attrs:{"color":"transparent","dense":""}},[_c('v-list-item-content',_vm._l((_vm.ntp.missing_field),function(field,index){return _c('v-list-item-title',{key:index,staticClass:"text-body-2",attrs:{"color":"white"}},[_vm._v(" "+_vm._s(field)+" ")])}),1)],1)],1):_vm._e(),(
      _vm.projectRecord.project_phase === 'Construction' &&
      _vm.closeOut.missing_field !== null
    )?_c('div',[_c('span',[_vm._v(" The following information is required "),_c('strong',[_vm._v("before")]),_vm._v(" the Close Out Letter can be distributed: ")]),_c('v-list',{attrs:{"color":"transparent","dense":""}},[_c('v-list-item-content',_vm._l((_vm.closeOut.missing_field),function(field,index){return _c('v-list-item-title',{key:index,staticClass:"text-body-2",attrs:{"color":"white"}},[_vm._v(" "+_vm._s(field)+" ")])}),1)],1)],1):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }