<script>
  import { defineComponent } from "@vue/composition-api";
  import * as timeago from "timeago.js";

  import { useRouter } from "@/helpers";
  import { formatDate } from "@/helpers/filter.js";

  import { getProjectFromStore } from "../../useProject";

  export default defineComponent({
    setup() {
      const { route } = useRouter();
      const { projectRecord } = getProjectFromStore(route.value.params.project);

      return {
        timeago,
        projectRecord,
        formatDate
      };
    }
  });
</script>

<template>
  <v-card flat class="mb-7">
    <v-card-title class="d-flex justify-space-between mb-5">
      Key Dates
    </v-card-title>

    <v-card-text>
      <v-timeline dense class="timeline-custom-dense timeline-custom-dots">
        <!-- Close Out Date -->
        <v-timeline-item
          v-if="projectRecord.close_out_date"
          color="success"
          small
        >
          <div class="d-flex justify-space-between align-center flex-wrap">
            <h4 class="font-weight-bold me-1">Close Out Date</h4>
            <small v-if="projectRecord.end_date" class="text-no-wrap">
              {{ timeago.format(projectRecord.close_out_date, "en") }}
            </small>
          </div>
          <p class="mb-0">
            {{
              formatDate(projectRecord.close_out_date, {
                year: "numeric",
                month: "long",
                day: "numeric"
              })
            }}
          </p>
        </v-timeline-item>

        <!-- Actual Completion Date -->
        <v-timeline-item v-if="projectRecord.end_date" color="success" small>
          <div class="d-flex justify-space-between align-center flex-wrap">
            <h4 class="font-weight-bold me-1">Actual Completion Date</h4>
            <small v-if="projectRecord.end_date" class="text-no-wrap">
              {{ timeago.format(projectRecord.end_date, "en") }}
            </small>
          </div>
          <p class="mb-0">
            {{
              formatDate(projectRecord.end_date, {
                year: "numeric",
                month: "long",
                day: "numeric"
              })
            }}
          </p>
        </v-timeline-item>

        <!-- Actual Start Date -->
        <v-timeline-item v-if="projectRecord.start_date" color="success" small>
          <div class="d-flex justify-space-between align-center flex-wrap">
            <h4 class="font-weight-bold me-1">Actual Start Date</h4>
            <small v-if="projectRecord.start_date" class="text-no-wrap">
              {{ timeago.format(projectRecord.start_date, "en") }}
            </small>
          </div>
          <p class="mb-0">
            {{
              formatDate(projectRecord.start_date, {
                year: "numeric",
                month: "long",
                day: "numeric"
              })
            }}
          </p>
        </v-timeline-item>

        <!-- Rain Start Date -->
        <v-timeline-item color="info" small>
          <div class="d-flex justify-space-between align-center flex-wrap">
            <h4 class="font-weight-bold me-1">Rain Start Date</h4>
            <small v-if="projectRecord.rain_start_date" class="text-no-wrap">
              {{ timeago.format(projectRecord.rain_start_date, "en") }}
            </small>
          </div>
          <p v-if="projectRecord.rain_start_date" class="mb-0">
            {{
              formatDate(projectRecord.rain_start_date, {
                year: "numeric",
                month: "long",
                day: "numeric"
              })
            }}
          </p>
          <p v-else class="error--text font-weight-medium mb-0">Data Missing</p>
        </v-timeline-item>

        <!-- Proceed Date -->
        <v-timeline-item color="primary" small>
          <div class="d-flex justify-space-between align-center flex-wrap">
            <h4 class="font-weight-bold me-1">Proceed Date</h4>
            <small v-if="projectRecord.proceed_date" class="text-no-wrap">
              {{ timeago.format(projectRecord.proceed_date, "en") }}
            </small>
          </div>
          <p v-if="projectRecord.proceed_date" class="mb-1">
            {{
              formatDate(projectRecord.proceed_date, {
                year: "numeric",
                month: "long",
                day: "numeric"
              })
            }}
          </p>
          <p v-else class="error--text font-weight-medium mb-0">Data Missing</p>
        </v-timeline-item>

        <!-- Hand Off Date -->
        <v-timeline-item color="error" small>
          <div class="d-flex justify-space-between align-center flex-wrap">
            <h4 class="font-weight-bold me-1">Hand-Off Date</h4>
            <small v-if="projectRecord.hand_off_date" class="text-no-wrap">
              {{ timeago.format(projectRecord.hand_off_date, "en") }}
            </small>
          </div>
          <p v-if="projectRecord.hand_off_date" class="mb-0">
            {{
              formatDate(projectRecord.hand_off_date, {
                year: "numeric",
                month: "long",
                day: "numeric"
              })
            }}
          </p>
          <p v-else class="error--text font-weight-medium mb-0">Data Missing</p>
        </v-timeline-item>
      </v-timeline>
    </v-card-text>
  </v-card>
</template>
