<script>
  import { defineComponent } from "@vue/composition-api";

  import ProjectDocsAvailable from "../project-docs-available/ProjectDocsAvailable.vue";
  import ProjectMissingDataAlert from "../project-missing-data-alert/ProjectMissingDataAlert.vue";
  import ProjectStatusBar from "../project-status-bar/ProjectStatusBar.vue";

  export default defineComponent({
    components: {
      ProjectMissingDataAlert,
      ProjectStatusBar,
      ProjectDocsAvailable
    },
    setup() {}
  });
</script>

<template>
  <div class="user-tab-overview">
    <v-row>
      <v-col cols="12">
        <project-missing-data-alert />
        <project-status-bar class="mb-3" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <project-docs-available />
      </v-col>
    </v-row>
  </div>
</template>
