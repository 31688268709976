<script>
  import { mdiAlertOutline } from "@mdi/js";
  import { defineComponent } from "@vue/composition-api";

  import { useRouter } from "@/helpers";
  import { getProjectFromStore } from "@/views/apps/project/useProject";

  export default defineComponent({
    setup() {
      const { route } = useRouter();
      const { projectRecord } = getProjectFromStore(route.value.params.project);

      return {
        projectRecord,
        icons: {
          mdiAlertOutline
        }
      };
    }
  });
</script>

<template>
  <div>
    <v-alert
      v-if="projectRecord.contractor_record == null"
      dense
      text
      color="error"
      border="right"
      :icon="icons.mdiAlertOutline"
      transition="slide-x-transition"
    >
      <span>
        A contractor must be designated before traffic statement items can be
        added. You can assign the contractor by clicking the
        <strong>Edit</strong> button in the
        <strong>Project Info Panel</strong> on the left.
      </span>
    </v-alert>
    <v-alert
      v-if="projectRecord.contractor_list_record == null"
      dense
      text
      color="error"
      border="right"
      :icon="icons.mdiAlertOutline"
      transition="slide-x-transition"
    >
      <span>
        A contractor list must be designated before traffic statement items can
        be added. You can assign the contractor list by clicking the
        <strong>Edit</strong> button in the <strong>Financial</strong> tab.
        Please note that the list must match the fiscal year of the Short List
        and the contractor name of the associated Prime Contractor.
      </span>
    </v-alert>
    <v-alert
      v-if="projectRecord.short_list_record == null"
      dense
      text
      color="error"
      border="right"
      :icon="icons.mdiAlertOutline"
      transition="slide-x-transition"
    >
      <span>
        A short list must be designated before traffic statement items can be
        added. You can add a short list by clicking the
        <strong>Edit</strong> button in the <strong>Financial</strong> tab.
      </span>
    </v-alert>
  </div>
</template>
