<script>
  import { mdiCurrencyUsd, mdiPercentOutline } from "@mdi/js";
  import { computed, defineComponent, ref } from "@vue/composition-api";
  // Using VeeValidate for validations
  import { ValidationObserver, ValidationProvider } from "vee-validate";

  // Notification
  import { snackbar } from "@/components/base/snackbar/useSnackbar";
  import { useContractorListLookup } from "@/composables/useContractorLists";
  // Populate the dropdown components
  import { useContractLookup } from "@/composables/useContracts";
  import { useShortListLookup } from "@/composables/useShortLists";
  import { store } from "@/store";

  import { useProjectDialog } from "../../useProject";

  export default defineComponent({
    components: {
      ValidationProvider,
      ValidationObserver
    },
    props: {
      isProjectTabFinancialDialogOpen: {
        type: Boolean,
        required: true
      },
      editItem: {
        type: Object,
        required: true
      }
    },
    setup(props, { emit }) {
      // Populate the dropdown components
      const { activeConstructionContracts } = useContractLookup();
      const { contractorLists, groupedContractorLists } =
        useContractorListLookup();
      const { shortLists } = useShortListLookup();
      const { loading } = useProjectDialog();
      const form = ref(null);
      const overlay = ref(false);
      const valueChanged = ref(false);

      const updateShortListValue = () => {
        valueChanged.value = true;
      };

      const isLoading = computed(() => {
        return store.state.projects.loading;
      });

      // form submit
      const handleFormSubmit = async () => {
        try {
          store.dispatch("projects/updateLoading", true);
          emit("save", props.editItem);
          form.value.reset();
        } catch (error) {
          snackbar({
            text: `Unable to submit form. Please contact your Administrator.`,
            color: "error"
          });
        } finally {
          valueChanged.value = false;
        }
      };

      // form cancel
      const onCancel = () => {
        emit("close-dialog", false);
        valueChanged.value = false;
      };

      return {
        activeConstructionContracts,
        contractorLists,
        groupedContractorLists,
        shortLists,
        form,
        loading,
        overlay,
        isLoading,
        valueChanged,
        updateShortListValue,
        handleFormSubmit,
        onCancel,
        icons: {
          mdiPercentOutline,
          mdiCurrencyUsd
        }
      };
    }
  });
</script>

<template>
  <validation-observer ref="form" v-slot="{ invalid, reset }">
    <v-dialog
      :value="isProjectTabFinancialDialogOpen"
      scrollable
      max-width="700px"
      persistent
    >
      <v-card>
        <v-card-title> Edit Project Financials </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <form @submit.prevent="handleFormSubmit" @reset.prevent="reset">
            <v-row>
              <v-col cols="12" md="6">
                <!-- Contract -->
                <validation-provider
                  v-slot="{ errors }"
                  name="Contract"
                  rules=""
                >
                  <label
                    for="contract"
                    class="label-color font-weight-semibold mb-2 d-block"
                  >
                    Contract
                  </label>
                  <v-select
                    id="contract"
                    v-model="editItem.contract_record"
                    outlined
                    dense
                    :items="activeConstructionContracts"
                    item-value="fulcrum_id"
                    item-text="contract_num"
                    clearable
                    :error-messages="errors"
                    @input="$emit('update:modelValue', $event)"
                  />
                </validation-provider>

                <!-- Contractor List -->
                <validation-provider
                  v-slot="{ errors }"
                  vid="contractor"
                  name="Contractor List"
                  rules=""
                >
                  <label
                    for="contractorList"
                    class="label-color font-weight-semibold mb-2 d-block"
                  >
                    Contractor List
                  </label>
                  <v-select
                    id="contractorList"
                    v-model="editItem.contractor_list_record"
                    outlined
                    dense
                    :items="groupedContractorLists"
                    item-value="fulcrum_id"
                    clearable
                    :error-messages="errors"
                    @input="$emit('update:modelValue', $event)"
                  >
                    <template v-slot:selection="{ item }">
                      {{ item.contractor_name }} {{ item.list_year }}
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item>
                          {{ item.contractor_name }} {{ item.list_year }}
                        </v-list-item>
                      </v-list-item-content>
                    </template>
                  </v-select>
                </validation-provider>

                <!-- Short List -->
                <validation-provider
                  v-slot="{ errors }"
                  vid="short"
                  name="Short List"
                  rules=""
                >
                  <label
                    for="shortList"
                    class="label-color font-weight-semibold mb-2 d-block"
                  >
                    Short List <br />
                    <span
                      v-if="editItem.short_list_record"
                      class="text-xsmall error--text"
                      >Once saved this cannot be changed</span
                    >
                  </label>
                  <v-select
                    id="shortList"
                    v-model="editItem.short_list_record"
                    outlined
                    dense
                    :items="shortLists"
                    item-value="fulcrum_id"
                    item-text="short_list_name"
                    clearable
                    :disabled="editItem.short_list_record && !valueChanged"
                    :error-messages="errors"
                    @input="$emit('update:modelValue', $event)"
                    @change="updateShortListValue"
                  />
                </validation-provider>
              </v-col>

              <v-col cols="12" md="6">
                <!-- Initial Amount -->
                <validation-provider
                  v-slot="{ errors }"
                  name="Initial Amount"
                  rules="double:2"
                >
                  <label
                    for="initial-amount"
                    class="label-color font-weight-semibold mb-2 d-block"
                  >
                    Initial Amount
                  </label>
                  <v-text-field
                    id="initial-amount"
                    v-model="editItem.initial_amount"
                    outlined
                    clearable
                    dense
                    :error-messages="errors"
                    :prepend-inner-icon="icons.mdiCurrencyUsd"
                    @input="$emit('update:modelValue', $event)"
                  />
                </validation-provider>

                <!-- Expedited -->
                <validation-provider
                  v-slot="{ errors }"
                  name="Expedited"
                  rules=""
                >
                  <label
                    for="expedited"
                    class="label-color font-weight-semibold mb-2 d-block"
                  >
                    Expedited
                  </label>
                  <v-select
                    id="expedited"
                    v-model="editItem.expedited"
                    outlined
                    dense
                    :items="['Yes', 'No']"
                    clearable
                    :error-messages="errors"
                    @input="$emit('update:modelValue', $event)"
                  />
                </validation-provider>

                <!-- Contingency -->
                <validation-provider
                  v-slot="{ errors }"
                  name="Contingency"
                  rules="double:2"
                >
                  <label
                    for="contingency"
                    class="label-color font-weight-semibold mb-2 d-block"
                  >
                    <br v-if="editItem.short_list_record" />
                    Contingency
                  </label>
                  <v-text-field
                    id="contingency"
                    v-model.number="editItem.contingency"
                    :value="editItem.contingency"
                    outlined
                    dense
                    :error-messages="errors"
                    :append-icon="icons.mdiPercentOutline"
                    @input="$emit('update:modelValue', $event)"
                  >
                  </v-text-field>
                </validation-provider>
              </v-col>
            </v-row>
          </form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text type="reset" color="secondary" @click="onCancel">
            Cancel
          </v-btn>
          <v-btn
            type="submit"
            color="primary"
            :disabled="invalid"
            @click="handleFormSubmit"
          >
            Save
          </v-btn>
        </v-card-actions>
        <v-overlay :value="isLoading">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-card>
    </v-dialog>
  </validation-observer>
</template>
