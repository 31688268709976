/**
 * @file Short Lists
 * @author Anne Canoune
 * @version 0.1.0
 * @description All of the queries associated with the Short Lists service
 */

/**
 * Find All Short Lists
 * @description Returns all short lists.
 *
 *
 * @returns {object}
 */

function findAllShortLists() {
  return {
    query: {
      status: "Active",
      $sort: {
        list_year: 1
      }
    }
  };
}
exports.findAllShortLists = findAllShortLists;
