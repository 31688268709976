<script>
  import { mdiFilePdfBox } from "@mdi/js";
  import { defineComponent, ref } from "@vue/composition-api";

  import { useRouter } from "@/helpers";
  import { formatDate } from "@/helpers/filter.js";

  import {
    getProjectFromStore,
    resolveProjectDocumentsList
  } from "../../useProject";
  import { updateProjectStatus } from "../project-status-bar/useProjectStatusBar";
  // 3rd Party
  //import { PerfectScrollbar } from "vue2-perfect-scrollbar";
  export default defineComponent({
    setup() {
      const { route } = useRouter();
      const { projectRecord } = getProjectFromStore(route.value.params.project);
      const { projectDocuments, newProjectCount } =
        resolveProjectDocumentsList(projectRecord);

      const docDateFormat = {
        month: "long",
        day: "2-digit",
        year: "numeric"
      };

      const { fetchReport } = updateProjectStatus();
      const overlayIsVisible = ref(false);

      async function handleReportFetch(fulcrumId, shouldEmail, reportType) {
        overlayIsVisible.value = true;
        await fetchReport(fulcrumId, shouldEmail, reportType);
        overlayIsVisible.value = false;
      }

      return {
        projectDocuments,
        newProjectCount,
        formatDate,
        icons: {
          mdiFilePdfBox
        },
        docDateFormat,
        overlayIsVisible,
        fetchReport,
        handleReportFetch
      };
    }
  });
</script>

<template>
  <v-card v-if="projectDocuments" flat class="mb-7">
    <v-card-title>
      <p class="mb-2 mb-sm-0">Documents Available for Download</p>
      <v-spacer></v-spacer>
      <v-chip
        v-if="newProjectCount > 0"
        class="v-chip-light-bg primary--text font-weight-semibold"
        small
      >
        {{ newProjectCount }} New
      </v-chip>
    </v-card-title>
    <v-card-text>
      <span
        v-if="projectDocuments.length == 0"
        class="d-flex justify-center my-5"
      >
        There are no documents to display
      </span>
      <v-list v-else>
        <v-divider></v-divider>
        <!-- Documents -->
        <template v-for="(document, index) in projectDocuments">
          <v-list-item
            link
            :key="document.reportType"
            @click="
              handleReportFetch(document.fulcrumId, false, document.reportType)
            "
          >
            <!-- Avatar -->
            <v-list-item-avatar class="justify-center" size="38">
              <v-icon color="error">
                {{ icons.mdiFilePdfBox }}
              </v-icon>
            </v-list-item-avatar>

            <!-- Content -->
            <v-list-item-content class="d-block">
              <v-list-item-title class="text-sm font-weight-semibold">
                {{ document.title }}
              </v-list-item-title>
              <v-list-item-subtitle class="text-sm">
                {{ document.documentDescription }}
              </v-list-item-subtitle>
            </v-list-item-content>

            <!-- Item Action/Time -->
            <v-list-item-action>
              <span class="text--secondary text-xs">
                {{ formatDate(document.dateCreated, docDateFormat) }}
              </span>
            </v-list-item-action>
          </v-list-item>
          <v-divider
            v-if="projectDocuments.index !== projectDocuments.length - 1"
            :key="index"
          ></v-divider>
        </template>
      </v-list>
    </v-card-text>
    <v-overlay v-model="overlayIsVisible" absolute color="white" opacity="0.75">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>
