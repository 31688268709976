<script>
  import {
    mdiBriefcaseEyeOutline,
    mdiCalendarBlankOutline,
    mdiClipboardCheckOutline,
    mdiCurrencyUsd
  } from "@mdi/js";
  import { defineComponent, ref } from "@vue/composition-api";

  // components
  import Breadcrumbs from "@/components/common/breadcrumbs/Breadcrumbs.vue";
  import { useProjectViewBreadcrumbs } from "@/components/common/breadcrumbs/useBreadcrumbs";

  import { useProject } from "../useProject";
  import ProjectInfoPanel from "./project-info-panel/ProjectInfoPanel.vue";
  import ProjectTabConstructionRecords from "./project-tab-construction-records/ProjectTabConstructionRecords.vue";
  import ProjectTabFinancial from "./project-tab-financial/ProjectTabFinancial.vue";
  // import ProjectStatusBar from "./project-status-bar/ProjectStatusBar.vue";
  import ProjectTabOverview from "./project-tab-overview/ProjectTabOverview.vue";
  import ProjectTabSchedule from "./project-tab-schedule/ProjectTabSchedule.vue";

  export default defineComponent({
    components: {
      Breadcrumbs,
      ProjectInfoPanel,
      // ProjectStatusBar,
      ProjectTabOverview,
      ProjectTabSchedule,
      ProjectTabFinancial,
      ProjectTabConstructionRecords
    },
    setup() {
      const { project, projectHasLoaded } = useProject();
      const { breadcrumbs } = useProjectViewBreadcrumbs();
      const projectTab = ref(null);
      const tabs = [
        { icon: mdiBriefcaseEyeOutline, title: "Overview" },
        { icon: mdiCalendarBlankOutline, title: "Schedule" },
        { icon: mdiCurrencyUsd, title: "Financial" },
        { icon: mdiClipboardCheckOutline, title: "Permits" }
      ];

      return {
        project,
        projectHasLoaded,
        breadcrumbs,
        projectTab,
        tabs
      };
    }
  });
</script>

<template>
  <div v-if="projectHasLoaded" id="user-view">
    <breadcrumbs
      class="mb-5"
      :items="breadcrumbs"
      :active-link="{
        text: project.proceed_num,
        disabled: true,
        href: `/apps/project/view/${project.fulcrum_id}`
      }"
    />
    <v-row>
      <v-col cols="12" md="4" lg="4">
        <project-info-panel
          v-if="projectHasLoaded"
          :project-data="project"
        ></project-info-panel>
      </v-col>
      <v-col cols="12" md="8" lg="8">
        <!-- <project-status-bar class="mb-5" /> -->
        <v-tabs v-model="projectTab" show-arrows class="user-tabs">
          <v-tab v-for="tab in tabs" :key="tab.icon">
            <v-icon size="20" class="me-3">
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items
          id="user-tabs-content"
          v-model="projectTab"
          class="mt-5 pa-1"
        >
          <!-- Project Overview Tab -->
          <v-tab-item v-if="projectHasLoaded">
            <project-tab-overview :project="project" />
          </v-tab-item>

          <!-- Project Schedule Tab -->
          <v-tab-item v-if="projectHasLoaded">
            <project-tab-schedule :project-id="project.fulcrum_id" />
          </v-tab-item>

          <!-- Financial Overview Tab -->
          <v-tab-item v-if="projectHasLoaded">
            <project-tab-financial :project-id="project.fulcrum_id" />
          </v-tab-item>

          <!-- Permit Overview Tab -->
          <v-tab-item v-if="projectHasLoaded">
            <project-tab-construction-records
              :project-record-id="project.fulcrum_id"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss">
  @import "@/preset/preset/apps/user.scss";
</style>
