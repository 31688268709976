import { computed, getCurrentInstance, ref } from "@vue/composition-api";
import Vue from "vue";
const { Project } = Vue.$FeathersVuex.api;
import { snackbar } from "@/components/base/snackbar/useSnackbar";
import { store } from "@/store";

export default (status) => {
  /**
   * NOTE: "TS Rejected", while it is a status, is not a "milestone"
   * status. Here it is being used as a possible state of the
   * "TS Reviewed" milestone status.
   *
   */
  const tsStatuses = ref([
    "TS Not Started",
    "TS In Progress",
    "TS Submitted",
    "TS Reviewed",
    "TS Accepted",
    "TS Ok'd for Distro",
    "NTP"
  ]);
  const constStatuses = ref([
    "NTP",
    "Active Construction",
    "Substantially Complete",
    "Complete",
    "Closed Out"
  ]);

  const activeStep = computed(() => {
    //console.log(status);
    if (tsStatuses.value.indexOf(status) !== -1) {
      return tsStatuses.value.indexOf(status) + 1;
    } else {
      return constStatuses.value.indexOf(status) + 1;
    }
  });

  return {
    tsStatuses,
    constStatuses,
    activeStep
  };
};
export function useTsStatusBar(status) {
  const statuses = ref([
    "TS Not Started",
    "TS In Progress",
    "TS Submitted",
    "TS Reviewed",
    "TS Accepted",
    "TS Ok'd for Distro",
    "NTP"
  ]);

  /**
   * Get the current step so that the label is bolded.
   * We accomplish this by adding 1 to the index of the
   * project status and returning it.
   *
   * NOTE: "TS Rejected", while it is a status, is not a "milestone"
   * status. Here it is used as a possible state of the "TS Reviewed"
   * milestone status.
   */
  const activeStep = computed(() => {
    if (statuses.value.indexOf(status) === -1) {
      return 3 + 1;
    } else {
      return statuses.value.indexOf(status) + 1;
    }
  });

  return {
    statuses,
    activeStep
  };
}

export function useConstStatusBar(status) {
  const constStatuses = ref([
    "NTP",
    "Active Construction",
    "Substantially Complete",
    "Complete",
    "Closed Out"
  ]);
  /**
   * Get the current step so that the label is bolded.
   * We accomplish this by adding 1 to the index of the
   * project status and returning it.
   */
  const activeStep = computed(() => {
    return constStatuses.value.indexOf(status) + 1;
  });

  console.debug("These are the construction statuses: ", constStatuses.value);
  return {
    constStatuses: constStatuses.value,
    activeStep
  };
}

export function updateProjectStatus() {
  const { emit } = getCurrentInstance();

  const handleProjectStatusChange = async (status, projectId) => {
    try {
      emit("show-overlay", true);
      let project = new Project({});
      let projectClone = project.clone();
      Object.assign(projectClone, store.state.projects.keyedById[projectId]);
      projectClone.status = status;

      if (projectClone.fulcrum_id) {
        const saveProject = await projectClone.patch();
        console.log(saveProject);
      }

      snackbar({
        text: `This project's status has been updated.`,
        color: "success"
      });
      emit("show-overlay", false);
    } catch (error) {
      snackbar({
        text: `Error encountered while trying to update this project's status. Please contact your Administrator.`,
        color: "error"
      });
    } finally {
      emit("force-rerender");
      emit("show-overlay", false);
    }
  };

  const fetchReport = async (projectId, emailReport, reportType) => {
    try {
      emit("show-overlay", true);
      snackbar({
        text: `One moment while we fetch your report.`,
        color: "info"
      });
      const response = await store.dispatch("reports/create", {
        report_type: reportType,
        email: emailReport,
        fulcrum_id: projectId
      });

      const link = document.createElement("a");
      link.href = response.pdf_link;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      snackbar({
        text: `Error encountered while trying to fetch this report. Please contact your Administrator.`,
        color: "error"
      });
    } finally {
      emit("show-overlay", false);
    }
  };

  const handleNotification = async (
    status,
    projectId,
    emailReport,
    reportType
  ) => {
    await handleProjectStatusChange(status, projectId);
    await fetchReport(projectId, emailReport, reportType);
  };

  return {
    handleProjectStatusChange,
    fetchReport,
    handleNotification
  };
}
