<script>
  import { mdiAlertOutline } from "@mdi/js";
  import { defineComponent } from "@vue/composition-api";

  import { useRouter } from "@/helpers";

  import {
    getProjectFromStore,
    resolveMissingCloseOutFields,
    resolveMissingNtpFields
  } from "../../useProject";

  export default defineComponent({
    setup() {
      const { route } = useRouter();
      const { projectRecord } = getProjectFromStore(route.value.params.project);
      const { ntp, ntpHasLoaded } = resolveMissingNtpFields(
        route.value.params.project
      );
      const { closeOut, closeOutHasLoaded } = resolveMissingCloseOutFields(
        route.value.params.project
      );
      return {
        icons: {
          mdiAlertOutline
        },
        projectRecord,
        ntp,
        ntpHasLoaded,
        closeOut,
        closeOutHasLoaded
      };
    }
  });
</script>

<template>
  <v-alert
    dense
    text
    color="error"
    border="right"
    :icon="icons.mdiAlertOutline"
    transition="slide-x-transition"
    v-if="
      (ntpHasLoaded &&
        projectRecord.project_phase === 'Design' &&
        ntp.missing_field !== null) ||
      (projectRecord.project_phase === 'Construction' &&
        closeOutHasLoaded &&
        closeOut.missing_field !== null)
    "
  >
    <div
      v-if="
        projectRecord.project_phase === 'Design' && ntp.missing_field !== null
      "
    >
      <span>
        The following information is required <strong>before</strong> the NTP
        can be distributed:
      </span>
      <v-list color="transparent" dense>
        <v-list-item-content>
          <v-list-item-title
            v-for="(field, index) in ntp.missing_field"
            :key="index"
            class="text-body-2"
            color="white"
          >
            {{ field }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list>
    </div>
    <div
      v-if="
        projectRecord.project_phase === 'Construction' &&
        closeOut.missing_field !== null
      "
    >
      <span>
        The following information is required <strong>before</strong> the Close
        Out Letter can be distributed:
      </span>
      <v-list color="transparent" dense>
        <v-list-item-content>
          <v-list-item-title
            v-for="(field, index) in closeOut.missing_field"
            :key="index"
            class="text-body-2"
            color="white"
          >
            {{ field }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list>
    </div>
  </v-alert>
</template>
