<script>
  import {
    mdiChevronDown,
    mdiEmailFastOutline,
    mdiEyeOutline,
    mdiFileCheckOutline,
    mdiThumbDownOutline
  } from "@mdi/js";
  import { computed, defineComponent } from "@vue/composition-api";

  import {
    resolveMissingCloseOutFields,
    resolveMissingNtpFields
  } from "../../useProject";
  import { updateProjectStatus } from "./useProjectStatusBar";

  export default defineComponent({
    props: {
      projectPhase: {
        type: String,
        required: true
      },
      projectId: {
        type: String,
        required: true
      }
    },
    setup(props) {
      const { ntp, ntpHasLoaded } = resolveMissingNtpFields(props.projectId);
      const { closeOut, closeOutHasLoaded } = resolveMissingCloseOutFields(
        props.projectId
      );
      const { handleProjectStatusChange, fetchReport, handleNotification } =
        updateProjectStatus();

      const isMissingNtpFields = computed(() => {
        if (
          props.projectPhase.value === "Construction" ||
          ntp.value.missing_field === null ||
          ntp.value.missing_field.length == 0
        ) {
          return false;
        } else {
          return true;
        }
      });

      const isMissingCloseOutFields = computed(() => {
        if (
          closeOut.value.missing_field === null ||
          closeOut.value.missing_field.length == 0
        ) {
          return false;
        } else {
          return true;
        }
      });

      return {
        icons: {
          mdiChevronDown,
          mdiFileCheckOutline,
          mdiThumbDownOutline,
          mdiEyeOutline,
          mdiEmailFastOutline
        },
        ntp,
        ntpHasLoaded,
        isMissingNtpFields,
        closeOut,
        closeOutHasLoaded,
        isMissingCloseOutFields,
        handleProjectStatusChange,
        fetchReport,
        handleNotification
      };
    }
  });
</script>

<template>
  <v-menu
    v-if="ntpHasLoaded && closeOutHasLoaded"
    offset-y
    :close-on-content-click="false"
  >
    <template #activator="{ on, attrs }">
      <v-btn dense small outlined color="primary" v-bind="attrs" v-on="on">
        Change Status
        <v-icon right dark>{{ icons.mdiChevronDown }}</v-icon>
      </v-btn>
    </template>
    <v-list dense>
      <v-list-item
        link
        v-if="projectPhase === 'Design'"
        @click="handleProjectStatusChange('TS Not Started', projectId)"
      >
        <v-list-item-content>
          <v-list-item-title>Not Started</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        link
        v-if="projectPhase === 'Design'"
        @click="handleProjectStatusChange('TS In Progress', projectId)"
      >
        <v-list-item-content>
          <v-list-item-title>In Progress</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        link
        v-if="projectPhase === 'Design'"
        @click="handleProjectStatusChange('TS Submitted', projectId)"
      >
        <v-list-item-content>
          <v-list-item-title>Submitted</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-group v-if="projectPhase === 'Design'">
        <template #activator>
          <v-list-item-content>
            <v-list-item-title class="accent--text"> Review </v-list-item-title>
          </v-list-item-content>
        </template>
        <v-list-item
          link
          @click="handleProjectStatusChange('TS Reviewed', projectId)"
        >
          <v-list-item-icon class="mr-2">
            <v-icon dense>{{ icons.mdiFileCheckOutline }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Has Been Reviewed</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          @click="handleProjectStatusChange('TS Rejected', projectId)"
        >
          <v-list-item-icon class="mr-2">
            <v-icon dense>{{ icons.mdiThumbDownOutline }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Rejected</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      <v-list-item
        link
        v-if="projectPhase === 'Design'"
        @click="handleProjectStatusChange('TS Accepted', projectId)"
      >
        <v-list-item-content>
          <v-list-item-title>Accepted</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        link
        v-if="projectPhase === 'Design'"
        @click="handleProjectStatusChange('TS Ok\'d for Distro', projectId)"
      >
        <v-list-item-content>
          <v-list-item-title>Ok'd for Distribution</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-group>
        <template #activator>
          <v-list-item-content>
            <v-list-item-title class="accent--text"> NTP </v-list-item-title>
          </v-list-item-content>
        </template>
        <v-list-item link @click="fetchReport(projectId, false, 'NTP')">
          <v-list-item-icon class="mr-2">
            <v-icon dense>{{ icons.mdiEyeOutline }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Preview Documents</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          :disabled="isMissingNtpFields"
          @click="handleNotification('NTP', projectId, true, 'NTP')"
        >
          <v-list-item-icon class="mr-2">
            <v-icon dense>{{ icons.mdiEmailFastOutline }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Issue NTP</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      <v-list-item
        link
        v-if="projectPhase === 'Construction'"
        @click="handleProjectStatusChange('Active Construction', projectId)"
      >
        <v-list-item-content>
          <v-list-item-title>Active Construction</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        link
        v-if="projectPhase === 'Construction'"
        @click="handleProjectStatusChange('Substantially Complete', projectId)"
      >
        <v-list-item-content>
          <v-list-item-title>Substantially Complete</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        link
        v-if="projectPhase === 'Construction'"
        @click="handleProjectStatusChange('Complete', projectId)"
      >
        <v-list-item-content>
          <v-list-item-title>Complete</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-group v-if="projectPhase === 'Construction'">
        <template #activator>
          <v-list-item-content>
            <v-list-item-title class="accent--text">
              Close Out
            </v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item link @click="fetchReport(projectId, false, 'CloseOut')">
          <v-list-item-icon class="mr-2">
            <v-icon dense>{{ icons.mdiEyeOutline }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Preview Document</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          :disabled="isMissingCloseOutFields"
          @click="handleNotification('Closed Out', projectId, true, 'CloseOut')"
        >
          <v-list-item-icon class="mr-2">
            <v-icon dense>{{ icons.mdiEmailFastOutline }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Close Out Project</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>
  </v-menu>
</template>
