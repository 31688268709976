import { computed } from "@vue/composition-api";
import { useFind } from "feathers-vuex";
import Vue from "vue";

import { useRouter } from "@/helpers";
const { ProjectBurnDown } = Vue.$FeathersVuex.api;

export function getBurnDownByProjectId() {
  const { route } = useRouter();

  const projectBurnDownsParams = computed(() => {
    return {
      query: {
        project_id: route.value.params.project,
        $sort: {
          item_date: 1
        }
      },
      paginate: false,
      qid: "projectBurnDownsList"
    };
  });
  const {
    items: projectBurnDowns,
    haveLoaded: projectBurnDownsHaveLoaded,
    isPending: projectBurnDownsArePending
  } = useFind({
    model: ProjectBurnDown,
    params: projectBurnDownsParams
  });

  return {
    projectBurnDowns,
    projectBurnDownsHaveLoaded,
    projectBurnDownsArePending
  };
}
